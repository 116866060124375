/**
 *
 * TwoFaSettings
 *
 */
import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import QRCode from 'qrcode.react';
// import {
//   AvForm,
//   AvGroup,
//   AvInput,
//   AvFeedback,
// } from 'availity-reactstrap-validation-safe';
// import Select from 'react-select';
import { Button, Form, Input, Modal, Steps } from 'antd';
import { CameraOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from '@/stores';
import { profileActions } from '@/stores/profile';
import { authActions } from '@/stores/auth';

interface Props {}

export const TwoFaSettings = memo((props: Props) => {
  const { user } = useSelector((state) => state.profile);
  const { qrCode, loading, isAuthenticatorVisible, isVerificationCodeVisible, token } = useSelector((state) => state.profile);
  const { loading: authLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleCloseAuthenticator = useCallback(() => {
    dispatch(profileActions.setIsAuthenticatorVisible(false));
    form.resetFields();
  }, []);

  const handleCloseVerification = useCallback(() => {
    // setIsVerificationVisible(false);
    dispatch(profileActions.setIsAuthenticationCodeVisible(false));
    form.resetFields();
  }, []);

  const handleAuthenticatorAppFinish = (event: { code: string }) => {
    // event.preventDefault();
    dispatch(
      profileActions.enableAuthenticator({
        // userId: user.userId,
        // verificationType: 'AuthenticatorApp',
        code: event.code?.trim?.(),
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(profileActions.getMe());
      })
      .catch((err) => console.log);
    handleCloseAuthenticator()
  };

  const handleVerificationForm = (event: { code: string }) => {
    // event.preventDefault();
    dispatch(
      authActions.verifyAuthenticator({
        // userId: user.userId,
        // contactId: selectedContact.value,
        // verificationType: 'SMS',
        // verificationType: 'AuthenticatorApp',
        code: event.code?.trim?.(),
        token: token,
      }),
    );
    handleCloseVerification();
  };

  const codeRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Modal
        open={isAuthenticatorVisible}
        onCancel={handleCloseAuthenticator}
        className=""
        title="2FA Authenticator App"
        okText="Confirm"
        okButtonProps={{ loading: loading[profileActions.enableAuthenticator.typePrefix], className: "bg-dark" }}
        onOk={form.submit}
        centered
      >
        <Form form={form} onFinish={handleAuthenticatorAppFinish}>
          <Steps
            direction="vertical"
            current={-1}
            progressDot={true}
            items={[
              {
                title: (
                  <>
                    <DownloadOutlined size={15} className="mr-2" />
                    Download
                  </>
                ),
                description: (
                  <p>
                    Download a two-factor authenticator app like Microsoft Authenticator or Google Authenticator for your Android or iOS
                    device.
                  </p>
                ),
              },
              {
                title: (
                  <>
                    <CameraOutlined size={15} className="mr-2" />
                    Scan
                  </>
                ),
                description: (
                  <div className="">
                    <p>
                      <span>Scan the QR Code below </span>
                        {/* or enter this key</span>{' '}
                      <strong style={{ wordBreak: 'break-all' }}>{qrCode?.base32}</strong>{' '} */}
                      <span>into your two factor authenticator app. </span>
                        {/* (Spaces and casing do not matter)</span> */}
                    </p>
                    {!_.isEmpty(qrCode) && <QRCode value={qrCode?.qrCode}></QRCode>}
                  </div>
                ),
              },
              {
                title: (
                  <>
                    <CheckCircleOutlined size={15} className="mr-2" />
                    Verification
                  </>
                ),
                description: (
                  <div className="">
                    <p>
                      Once you have successfully scanned the QR code, {' '}
                      {/* or input the key above,  */}
                      your two factor authenticator app will provide
                      you with a unique code. Please enter the code below and click verify to complete the process.
                    </p>
                    <Form.Item label="Verification Code" name="code">
                      <Input name="code" id="code" required />
                    </Form.Item>
                  </div>
                ),
              },
            ]}
          />
        </Form>
      </Modal>
      <Modal
        open={isVerificationCodeVisible}
        onCancel={handleCloseVerification}
        className=""
        title="Verification"
        okText="Verify"
        okButtonProps={{ loading: authLoading[authActions.verifyAuthenticator.typePrefix], className: "bg-dark"  }}
        onOk={form.submit}
        centered
      >
        <Form form={form} onFinish={handleVerificationForm}>
          <Form.Item name="code" label="Verification Code">
            <Input
              id="code"
              required
              // ref={codeRef}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
