import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "./auth";
import { IUser } from "@/types/app";
import { openNotification } from "@/utils";
import { mapUserFromResponse } from "./user";
import { uploadS3 } from "@/common/api/s3";

export interface IAppState {
  user?: IUser;
  loading: { [key: string]: boolean };
  userProfile?: IUser;
  qrCode?: any;
  isAuthenticatorVisible: boolean;
  isVerificationCodeVisible: boolean;
  token?: string;
}

export const initialState: IAppState = {
  user: undefined,
  loading: {},
  userProfile: undefined,
  isAuthenticatorVisible: false,
  isVerificationCodeVisible: false,
};

const getMe = createAsyncThunk(
  `${SLICE_NAME.PROFILE}/getMe`,
  async (query, { rejectWithValue }) => {
    try {
      const data = await api.getMe<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateMe = createAsyncThunk(
  `${SLICE_NAME.PROFILE}/updateMe`,
  async (form: Partial<IUser>, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(
          form?.attachmentUpload,
          "userLogos"
        );
        if (success) {
          form = {
            ...form,
            logo: attachment,
          };
        }
      }
      const data = await api.updateMe<{ data: IUser }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const disableAuthenticator = createAsyncThunk(`${SLICE_NAME.AUTH}/disableAuthenticator`, async (form: any | undefined, { rejectWithValue }) => {
  try {
    const response = await api.disableAuthenticator<{ data: IUser }>();
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});


const generateQRCode = createAsyncThunk(`${SLICE_NAME.AUTH}/generateQRCode`, async (form: any | undefined, { rejectWithValue }) => {
  try {
    const response = await api.generateQRCode<{ data: IUser }>(form);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const enableAuthenticator = createAsyncThunk(`${SLICE_NAME.AUTH}/enableAuthenticator`, async (form: any | undefined, { rejectWithValue }) => {
  try {
    const response = await api.enableAuthenticator<{ data: IUser }>(form);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const profileSlice = createSlice({
  name: SLICE_NAME.PROFILE,
  initialState: initialState,
  reducers: {
    onLogout: (state) => {
      state.user = undefined;
      localStorage.clear();
    },
    setIsAuthenticatorVisible: (state,{ payload })=>{
      state.isAuthenticatorVisible=payload;
    },
    setIsAuthenticationCodeVisible: (state,{ payload })=>{
      state.isVerificationCodeVisible=payload;
    },
    setToken: (state,{ payload })=>{
      state.token=payload;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(
    //   authActions.verifyEmailOTP.fulfilled,
    //   (state, { payload }) => {
    //     state.user = payload.data.user;
    //   }
    // );
    // builder.addCase(authActions.loginEmail.fulfilled, (state, { payload }) => {
    //   state.user = payload.data.user;
    // });
    // builder.addCase(
    //   authActions.registerEmail.fulfilled,
    //   (state, { payload }) => {
    //     state.user = payload.data.user;
    //   }
    // );
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.user = mapUserFromResponse(payload.data.data);
      state.loading[getMe.typePrefix] = false;
    });
    builder.addCase(getMe.pending, (state, { payload }) => {
      state.loading[getMe.typePrefix] = true;
    });
    builder.addCase(getMe.rejected, (state, { payload }) => {
      state.loading[getMe.typePrefix] = false;
    });
    builder.addCase(updateMe.fulfilled, (state, { payload }) => {
      // console.log(' E >>>>', payload.data);
      state.loading[updateMe.typePrefix] = false;
      // state.user = mapUserFromResponse(payload.data.data);
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateMe.pending, (state, { payload }) => {
      state.loading[updateMe.typePrefix] = true;
    });
    builder.addCase(updateMe.rejected, (state, { payload }) => {
      state.loading[updateMe.typePrefix] = false;
    });
    builder.addCase(disableAuthenticator.pending, (state, { payload }) => {
      state.loading[disableAuthenticator.typePrefix] = true;
    });
    builder.addCase(disableAuthenticator.rejected, (state, { payload }) => {
      state.loading[disableAuthenticator.typePrefix] = false;
    });
    builder.addCase(disableAuthenticator.fulfilled, (state, { payload }) => {
      state.loading[disableAuthenticator.typePrefix] = false;
      openNotification('success', 'Revoke authenticator successfully');
    });
    builder.addCase(generateQRCode.pending, (state, { payload }) => {
      state.loading[generateQRCode.typePrefix] = true;
    });
    builder.addCase(generateQRCode.rejected, (state, { payload }) => {
      state.loading[generateQRCode.typePrefix] = false;
    });
    builder.addCase(generateQRCode.fulfilled, (state, { payload }) => {
      state.loading[generateQRCode.typePrefix] = false;
      state.qrCode=payload.data.data;
    });

    builder.addCase(enableAuthenticator.pending, (state, { payload }) => {
      state.loading[enableAuthenticator.typePrefix] = true;
    });
    builder.addCase(enableAuthenticator.rejected, (state, { payload }) => {
      state.loading[enableAuthenticator.typePrefix] = false;
    });
    builder.addCase(enableAuthenticator.fulfilled, (state, { payload }) => {
      state.loading[enableAuthenticator.typePrefix] = false;
      openNotification('success', 'Turn authenticator successfully');
    });
  },
});

export const profileReducer = profileSlice.reducer;
export const profileCaseReducers = profileSlice.caseReducers;
export const profileActions = {
  ...profileSlice.actions,
  getMe,
  updateMe,
  disableAuthenticator,
  generateQRCode,
  enableAuthenticator,
};
