import api from '@/common/api';
import { EndpointsParams } from '@/common/api/endpoints';
import { SLICE_NAME } from '@/common/constants/stores';
import { GroupPermissionPayload, IGroupPermission, IGroupPermissionResponse, IPagination, IPaginationQuery } from '@/types/app';
import { openNotification } from '@/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface IGroupPermissionState {
  data?: IGroupPermission[];
  loading: Record<string, boolean>;
  pagination: IPagination;
  detail?: IGroupPermission;
  notePagination?: IPagination;
}

export const initialState: IGroupPermissionState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getGroupPermissions = createAsyncThunk(
  `${SLICE_NAME.GROUP_PERMISSIONS}/getGroupPermissions`,
  async (query: IPaginationQuery & EndpointsParams['getGroupPermissions'], { rejectWithValue }) => {
    try {
      const data = await api.getGroupPermissions<IGroupPermissionResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getGroupPermission = createAsyncThunk(
  `${SLICE_NAME.GROUP_PERMISSIONS}/getGroupPermission`,
  async (query: EndpointsParams['getGroupPermission'], { rejectWithValue }) => {
    try {
      const data = await api.getGroupPermission<{ data: IGroupPermission }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createGroupPermission = createAsyncThunk(
  `${SLICE_NAME.GROUP_PERMISSIONS}/createGroupPermission`,
  async (form: GroupPermissionPayload & EndpointsParams['createGroupPermission'], { rejectWithValue }) => {
    try {
      const data = await api.createGroupPermission<{ data: IGroupPermission }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateGroupPermission = createAsyncThunk(
  `${SLICE_NAME.GROUP_PERMISSIONS}/updateGroupPermission`,
  async (form: Partial<GroupPermissionPayload> & EndpointsParams['updateGroupPermission'], { rejectWithValue }) => {
    try {
      const data = await api.updateGroupPermission<{ data: IGroupPermission }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteGroupPermission = createAsyncThunk(
  `${SLICE_NAME.GROUP_PERMISSIONS}/deleteGroupPermission`,
  async (query: EndpointsParams['deleteGroupPermission'], { rejectWithValue }) => {
    try {
      const data = await api.deleteGroupPermission<{ data: IGroupPermission }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IGroupPermission) => {
  return {
    ...response,
  };
};

const groupPermissionSlice = createSlice({
  name: SLICE_NAME.GROUP_PERMISSIONS,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroupPermissions.fulfilled, (state, { payload }) => {
      state.loading[getGroupPermissions.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getGroupPermissions.pending, (state, { payload }) => {
      state.loading[getGroupPermissions.typePrefix] = true;
    });
    builder.addCase(getGroupPermissions.rejected, (state, { payload }) => {
      state.loading[getGroupPermissions.typePrefix] = false;
    });
    builder.addCase(getGroupPermission.fulfilled, (state, { payload }) => {
      state.loading[getGroupPermission.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getGroupPermission.pending, (state, { payload }) => {
      state.loading[getGroupPermission.typePrefix] = true;
    });
    builder.addCase(getGroupPermission.rejected, (state, { payload }) => {
      state.loading[getGroupPermission.typePrefix] = false;
    });
    builder.addCase(createGroupPermission.fulfilled, (state, { payload }) => {
      state.loading[createGroupPermission.typePrefix] = false;
      openNotification('success', `${payload.data.data?.name} created successfully!`);
    });
    builder.addCase(createGroupPermission.pending, (state, { payload }) => {
      state.loading[createGroupPermission.typePrefix] = true;
    });
    builder.addCase(createGroupPermission.rejected, (state, { payload }) => {
      state.loading[createGroupPermission.typePrefix] = false;
    });
    builder.addCase(updateGroupPermission.fulfilled, (state, { payload }) => {
      state.loading[updateGroupPermission.typePrefix] = false;
      openNotification('success', `${payload.data.data?.name} updated successfully!`);
    });
    builder.addCase(updateGroupPermission.pending, (state, { payload }) => {
      state.loading[updateGroupPermission.typePrefix] = true;
    });
    builder.addCase(updateGroupPermission.rejected, (state, { payload }) => {
      state.loading[updateGroupPermission.typePrefix] = false;
    });
    builder.addCase(deleteGroupPermission.fulfilled, (state, { payload }) => {
      state.loading[deleteGroupPermission.typePrefix] = false;
      openNotification('success', `Deleted successfully!`);
    });
    builder.addCase(deleteGroupPermission.pending, (state, { payload }) => {
      state.loading[deleteGroupPermission.typePrefix] = true;
    });
    builder.addCase(deleteGroupPermission.rejected, (state, { payload }) => {
      state.loading[deleteGroupPermission.typePrefix] = false;
    });
  },
});

export const groupPermissionReducer = groupPermissionSlice.reducer;
export const groupPermissionCaseReducers = groupPermissionSlice.caseReducers;
export const groupPermissionActions = {
  ...groupPermissionSlice.actions,
  getGroupPermissions,
  getGroupPermission,
  createGroupPermission,
  updateGroupPermission,
  deleteGroupPermission,
};
