import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEmployee, IEmployeeNote, IEmployeeNoteResponse, IEmployeeResponse } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { IEmployeeState } from './employee';
import { uploadS3 } from '@/common/api/s3';
import { EndpointsParams } from '@/common/api/endpoints';

export const initialState: IEmployeeState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  timesheetDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  guideDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  othersDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  payslipDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  notes: [],
  notePagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  banks: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
};

const getOnboardings = createAsyncThunk(`${SLICE_NAME.ONBOARDING}/getOnboardings`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getOnboardings<IEmployeeResponse>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getOnboarding = createAsyncThunk(`${SLICE_NAME.ONBOARDING}/getOnboarding`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getOnboarding<{ data: IEmployee }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateOnboarding = createAsyncThunk(`${SLICE_NAME.ONBOARDING}/updateOnboarding`, async (form: any, { rejectWithValue }) => {
  try {
    const data = await api.updateOnboarding<{ data: IEmployee }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateOnboardingStatus = createAsyncThunk(
  `${SLICE_NAME.ONBOARDING}/updateOnboardingStatus`,
  async (form: { registrationStatus: string; redirectURL: string; loginURL: string } & EndpointsParams['updateOnboardingStatus'], { rejectWithValue }) => {
    try {
      const data = await api.updateOnboardingStatus<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteOnboarding = createAsyncThunk(`${SLICE_NAME.ONBOARDING}/deleteOnboarding`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteOnboarding<{ data: IEmployee }>(query);

    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getOnboardingNotes = createAsyncThunk(
  `${SLICE_NAME.ONBOARDING}/getOnboardingNotes`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getOnboardingNotes<IEmployeeNoteResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createOnboardingNote = createAsyncThunk(
  `${SLICE_NAME.ONBOARDING}/createOnboardingNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'onboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createOnboardingNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateOnboardingNote = createAsyncThunk(
  `${SLICE_NAME.ONBOARDING}/updateOnboardingNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'onboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.updateOnboardingNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IEmployee) => {
  return {
    ...response,
    // dob: response.dob ? response.dob * 1000 : 0,
    startDate: response.startDate ? response.startDate * 1000 : 0,
    endDate: response.endDate ? response.endDate * 1000 : 0,
    anticipatedEndDate: response.anticipatedEndDate ? response.anticipatedEndDate * 1000 : 0,
  };
};

const onboardingSlice = createSlice({
  name: SLICE_NAME.ONBOARDING,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOnboardings.fulfilled, (state, { payload }) => {
      state.loading[getOnboardings.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getOnboardings.pending, (state, { payload }) => {
      state.loading[getOnboardings.typePrefix] = true;
    });
    builder.addCase(getOnboardings.rejected, (state, { payload }) => {
      state.loading[getOnboardings.typePrefix] = false;
    });
    builder.addCase(getOnboarding.fulfilled, (state, { payload }) => {
      state.loading[getOnboarding.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getOnboarding.pending, (state, { payload }) => {
      state.loading[getOnboarding.typePrefix] = true;
    });
    builder.addCase(getOnboarding.rejected, (state, { payload }) => {
      state.loading[getOnboarding.typePrefix] = false;
    });
    builder.addCase(updateOnboarding.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.ONBOARDING);
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateOnboarding.pending, (state, { payload }) => {
      state.loading[updateOnboarding.typePrefix] = true;
    });
    builder.addCase(updateOnboarding.rejected, (state, { payload }) => {
      state.loading[updateOnboarding.typePrefix] = false;
    });
    builder.addCase(updateOnboardingStatus.fulfilled, (state, { payload }) => {
      state.detail = mapFromResponse(payload.data.data);
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateOnboardingStatus.pending, (state, { payload }) => {
      state.loading[updateOnboardingStatus.typePrefix] = true;
    });
    builder.addCase(updateOnboardingStatus.rejected, (state, { payload }) => {
      state.loading[updateOnboardingStatus.typePrefix] = false;
    });
    builder.addCase(deleteOnboarding.fulfilled, (state, { payload }) => {
      openNotification('success', `${payload.data.data?.name || (payload.data.data?.forename+ " " + payload.data.data?.surname)} deleted successfully!`);
      state.loading[deleteOnboarding.typePrefix] = false;
    });
    builder.addCase(deleteOnboarding.pending, (state, { payload }) => {
      state.loading[deleteOnboarding.typePrefix] = true;
    });
    builder.addCase(deleteOnboarding.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteOnboarding.typePrefix] = false;
    });
    builder.addCase(getOnboardingNotes.fulfilled, (state, { payload }) => {
      state.loading[getOnboardingNotes.typePrefix] = false;
      state.notes = payload.data.data;
      state.notePagination = payload.data.pagination;
    });
    builder.addCase(getOnboardingNotes.pending, (state, { payload }) => {
      state.loading[getOnboardingNotes.typePrefix] = true;
    });
    builder.addCase(getOnboardingNotes.rejected, (state, { payload }) => {
      state.loading[getOnboardingNotes.typePrefix] = false;
    });
    builder.addCase(createOnboardingNote.fulfilled, (state, { payload }) => {
      state.loading[createOnboardingNote.typePrefix] = false;
      openNotification('success', 'Note created successfully!');
    });
    builder.addCase(createOnboardingNote.pending, (state, { payload }) => {
      state.loading[createOnboardingNote.typePrefix] = true;
    });
    builder.addCase(createOnboardingNote.rejected, (state, { payload }) => {
      state.loading[createOnboardingNote.typePrefix] = false;
      openNotification('error', 'Creation note failed!');
    });
    builder.addCase(updateOnboardingNote.fulfilled, (state, { payload }) => {
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateOnboardingNote.pending, (state, { payload }) => {
      state.loading[updateOnboardingNote.typePrefix] = true;
    });
    builder.addCase(updateOnboardingNote.rejected, (state, { payload }) => {
      state.loading[updateOnboardingNote.typePrefix] = false;
    });
  },
});

export const onboardingReducer = onboardingSlice.reducer;
export const onboardingCaseReducers = onboardingSlice.caseReducers;
export const onboardingActions = {
  ...onboardingSlice.actions,
  getOnboardings,
  getOnboarding,
  updateOnboarding,
  updateOnboardingStatus,
  deleteOnboarding,
  getOnboardingNotes,
  createOnboardingNote,
  updateOnboardingNote,
};
