
import { dateFormat } from '@/common/constants/format';
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
const ContractInfoDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title">Contract Info</p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Contract Type</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.contractType ? matchValueToLabel(constants?.contactTypes || [], detail?.contractType) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Start Date</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.startDate ? dayjs(detail?.startDate).format(dateFormat) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">End Date</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.endDate ? dayjs(detail?.endDate).format(dateFormat) : <p className="none">none</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default ContractInfoDetail;
