import { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import { dateFormat } from "@/common/constants/format";
import axios from "axios";
import { constantsToOptions } from "@/utils";
import { useSelector } from "@/stores";

const EmployeePersonalInfoForm = () => {
  const { constants, countries } = useSelector((state) => state.app);

  return (
      <Row gutter={16} className="rounded p-2">
        <Col sm={12}>
          <Form.Item label="Reference" name="referenceID">
            <Input placeholder="Enter reference" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'This field is required!' }]}>
            <Select className="w-100" options={constantsToOptions(constants?.employeeTitles || [])} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Forename"
            name="forename"
            rules={[
              { required: true, message: 'This field is required!' },
              {
                validator: (_, value) =>
                  value?.trim() === '' && value ? Promise.reject(new Error('Please enter not only blank space')) : Promise.resolve(),
              },
            ]}
          >
            <Input pattern="[a-zA-Z ]+" title="Please enter on alphabets only." placeholder="Enter forename" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Surname"
            name="surname"
            rules={[
              { required: true, message: 'This field is required!' },
              {
                validator: (_, value) =>
                  value?.trim() === '' && value ? Promise.reject(new Error('Please enter not only blank space')) : Promise.resolve(),
              },
            ]}
          >
            <Input pattern="[a-zA-Z ]+" title="Please enter on alphabets only." placeholder="Enter surname" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Date of birth"
            name="dob"
            getValueProps={(i) => ({
              value: i ? dayjs(i) : dayjs(),
            })}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <DatePicker format={dateFormat} className="w-100 p-12" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Employee Nationality"
            name="employeeNationalityID"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              placeholder="Select country"
              filterOption={(input: string, option: any) => {
                return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
              }}
              showSearch
            >
              {countries?.map((item: any, index: number) => {
                return (
                  <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                    <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                    {item?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label="Address" name="address">
            <Input placeholder="Enter address" maxLength={128} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Contact Number"
            name="phoneNumber"
            rules={[
              {
                pattern: /^[0-9]+$/,
                message: 'Please enter on numbers only.',
              },
            ]}
          >
            <Input placeholder="Enter contact number" maxLength={15} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: 'This field is required!' },
              {
                type: 'email',
                message: 'Invalid E-mail!',
              },
            ]}
          >
            <Input type="email" placeholder="Enter email address" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Anticipated End Date"
            name="anticipatedEndDate"
            getValueProps={(i) => ({
              value: i ? dayjs(i) : '',
            })}
          >
            <DatePicker format={dateFormat} className="w-100 p-12" disabled />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label="Reason For Termination" name="reasonForTermination">
            <Input.TextArea autoSize placeholder="Enter reason for termination" disabled maxLength={1000} />
          </Form.Item>
        </Col>
      </Row>
  );
};

export default EmployeePersonalInfoForm;
