import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '@/common/constants/routes';
import { INotification } from '@/types/app';
import { employeeActions } from '@/stores/employee';
import { useDispatch, useSelector } from '@/stores';
import { openNotification, showNotificationMessage } from '@/utils';
import { notificationActions } from '@/stores/notification';
import { message } from 'antd';
import { dateTimeFormat, pageSizeOptions } from '@/common/constants/format';
import dayjs from 'dayjs';
import { dateFormat, dateFormat2 } from '@/common/constants/format';

const NotificationLink = (props: { item: INotification }) => {
  const { item } = props;
  const { userInfo } = useSelector((state) => state.auth);
  const { detail } = useSelector((state) => state.employee);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onNotificationClick = async (item: INotification) => {
    if (!item?.seenAt) {
      await dispatch(
        notificationActions.markAsSeenNotification({
          id: item?.id,
        }),
      );
      await dispatch(notificationActions.getNotifications({}));
    }

    dispatch(
      employeeActions.getEmployee({
        role: userInfo?.role,
        id: item.metadata.id,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.statusCode === 200) {
          const detail = res.data.data;

          let text = '';

          if (detail?.status === 'active') {
            if (item?.type !== 'become_employee') {
              text = 'This employee became an employee';
            }
            navigate(`${ROUTE_PATH.EMPLOYEE_LIST}/${detail.id}`);
          } else if (detail?.status === 'in_review') {
            if (item?.type !== 'create_new_hire') {
              text = 'This employee was a new hire';
            }
            navigate(`${ROUTE_PATH.NEW_HIRE}/${detail.id}`);
          } else if (detail?.status === 'on_boarding') {
            if (item?.type !== 'onboarding') {
              text = 'This employee was in onboarding process';
            }
            navigate(`${ROUTE_PATH.ONBOARDING}/${detail.id}`);
          } else if (detail?.status === 'off_boarding') {
            if (item?.type !== 'off_boarding') {
              text = 'This employee was in offboarding process';
            }
            navigate(`${ROUTE_PATH.OFFBOARDING}/${detail.id}`);
          } else if (detail?.status === 'requested_termination') {
            if (item?.type !== 'requested_termination') {
              text = 'This employee was requested termination';
            }
            navigate(`${ROUTE_PATH.EMPLOYEE_LIST}/${detail.id}`);
          }

          if (text !== '') {
            message.warning(text);
          }
        } else {
          openNotification('error', 'Employee is not exist');
        }
      })
      .catch((error) => openNotification('error', error.message));
  };
  const renderNotificationTitle = (item?: INotification) => {
    switch (item?.type) {
      case 'become_employee':
        return (item?.metadata?.name || item?.metadata?.username) + ' became an employee';
      case 'create_new_hire':
        return 'New hire requested by ' + (item?.user?.name || item?.user?.username);
      case 'onboarding':
        return (
          'Onboarding in progress for ' + (item?.metadata?.name ||
          item?.metadata?.username) + ' by ' + (item?.user?.name ||
          item?.user?.username)
        );
      case 'off_boarding':
        return (
          'Offboarding Requested for ' + (item?.metadata?.name ||
          item?.metadata?.username) + ' by ' + (item?.user?.name ||
          item?.user?.username)
        );
      case 'requested_termination':
        return (
          'Termination Requested for ' + (item?.metadata?.name ||
          item?.metadata?.username) + ' by ' + (item?.user?.name ||
          item?.user?.username)
        );
      default:
        return '';
    }
  };

  return (
    <div
      className={`notification-item pl-3 mb-1 mt-1 border-bottom d-flex align-items-center cursor-pointer ${
        !item?.seenAt ? 'notification-highlight' : ''
      }`}
      key={item.id}
      onClick={() => onNotificationClick(item)}
    >
      <p className="mb-0 py-2">
        {/* <p>{showNotificationMessage(userInfo, item)}</p> */}
        <div style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 500 }}>
          {renderNotificationTitle(item)}
        </div>
        {/* <p>{dayjs(item?.metadata?.createdAt).format(dateFormat)}</p> */}
        <div style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 400, color: '#666564' }}>
          {/* {dayjs(item?.metadata?.createdAt || 0).format(dateTimeFormat)} */}
          {/* {item?.metadata?.createdAt} */}
          {item?.metadata?.createdAt ? dayjs.unix(item.metadata.createdAt).format('DD MMMM YYYY hh:mm A') : 0}
        </div>
      </p>
    </div>
  );
};

export default NotificationLink;
