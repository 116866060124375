import { IUser } from '@/types/app';
import { Button, Form, Grid, Input, Popconfirm, Space, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { EyeTwoTone, DeleteTwoTone, LoginOutlined, PlusOutlined } from '@ant-design/icons';
import { ROUTE_PATH } from '@/common/constants/routes';
import dayjs from 'dayjs';
import { dateFormat, dateTimeFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import { authActions } from '@/stores/auth';
import { UserRole } from '@/common/constants/enums';
import { getBreakPoint, matchValueToLabel } from '@/utils';
import { isRootAdmin } from '@/utils/auth';
import { DeleteIcon, EyeIcon, LoginAsIcon, SearchIcon } from '@/components/common/IconComponents';
import { SorterResult } from 'antd/es/table/interface';

const { useBreakpoint } = Grid;

const UserManagement = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { constants } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { data, loading, pagination } = useSelector((state) => state.user);

  const columns: ColumnsType<IUser> = [
    {
      title: 'Actions',
      key: 'action',
      width: 140,
      fixed: 'left',
      render: (_, record) => (
        <Space>
          <Tooltip title="Login As">
            <Button type="text" size="small" onClick={() => dispatch(authActions.loginAsUser(record.id))}>
              <LoginAsIcon />
            </Button>
          </Tooltip>
          <Link to={_.id}>
            <Tooltip title="View Detail">
              <Button type="text" size="small">
                <EyeIcon />
              </Button>
            </Tooltip>
          </Link>
          <Popconfirm
            title="Delete this employee"
            description="Are you sure to delete?"
            onConfirm={() => {
              dispatch(
                userActions.deleteUser({
                  id: record.id,
                }),
              )
                ?.unwrap()
                ?.then(() => {
                  dispatch(userActions.getUsers({}));
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button type="text" size="small">
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Full name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: true,
      render: (name, record) => (
        <div>
          <b>{name}</b>
          <p className="mb-0">{record.username ? '@' + record.username : ''}</p>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      sorter: true,
      render: (value) => {
        return (
          <Tag color={value === UserRole.admin ? 'purple' : value === UserRole.company ? 'magenta' : 'blue'} className="text-uppercase">
            {value}
          </Tag>
        );
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: 200,
      sorter: true,
      render: (value) => matchValueToLabel(constants?.departments || [], value),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 200,
      sorter: true,
      render: (countryID: string, elm: IUser) =>
        elm?.country?.id ? (
          <Space>
            <img src={elm?.country?.flag} width="16" height="12" alt={`${elm?.country?.name}`} className="mr-2" />
            {elm?.country?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (value) => <span>{dayjs(value).format(dateTimeFormat)}</span>,
    },
  ];

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(userActions.getUsers({ keyword }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      userActions.getUsers({
        page,
        limit: pageSize,
      }),
    );
  };

  const handleSortAndPaginationChange = (pagination: TablePaginationConfig, sorter: SorterResult<IUser> | SorterResult<IUser>[]) => {
    dispatch(
      userActions.getUsers({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
      }),
    );
  };

  useEffect(
    function () {
      console.log('userInfo', userInfo);

      if (userInfo?.role === UserRole.admin) dispatch(userActions.getUsers({}));
    },
    [userInfo],
  );

  return (
    <Spin tip="Loading" size="large" spinning={loading[userActions.getUsers.typePrefix]}>
      <div className="container-fluid">
        <div className="d-flex justify-content-between py-3">
          <div className="page-listing-title d-flex justify-content-between align-items-center mb-0">
            <span>User List</span>
          </div>
          <Link to={ROUTE_PATH.ADMIN_USER_MANAGEMENT_CREATE}>
            <Button type="primary" className="d-flex align-items-center">
              <PlusOutlined /> Add New
            </Button>
          </Link>
        </div>
        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1000 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UserManagement;
