import api from '@/common/api';
import { useDispatch, useSelector } from '@/stores';
import { userPermissionActions } from '@/stores/userPermission';
import { IGroupPermissionResponse, IUser } from '@/types/app';
import { openNotification } from '@/utils';
import type { ModalProps } from 'antd';
import { Form, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import DebouncedSearchSelect from '../DebouncedSearchSelect';

interface IProps extends ModalProps {
  data?: Partial<IUser> | null;
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

const SetPermissionGroupModal = (props: IProps) => {
  const { data, onClose, onSubmitSuccess, ...rest } = props;

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.userPermission);

  const [searchingPermissionGroup, setSearchingPermissionGroup] = useState(false);
  const [permissionGroupID, setPermissionGroupID] = useState<string | null>('');

  useEffect(() => {
    if (data?.userPermission?.permissionGroupID) {
      setPermissionGroupID(data.userPermission.permissionGroupID);
    } else {
      setPermissionGroupID('');
    }
  }, [data]);

  const handleSubmitForm = () => {
    if (!data || !permissionGroupID) return;

    dispatch(
      userPermissionActions.setUserPermission({
        userId: data?.id!,
        permissionGroupID,
        role: userInfo?.role!,
      }),
    )
      .unwrap()
      .then(() => {
        onSubmitSuccess?.();
        onClose?.();
      })
      .catch((res) => console.log('error', res.error));
  };

  return (
    <Modal
      {...rest}
      onCancel={() => onClose?.()}
      title="Set Permission Group"
      onOk={handleSubmitForm}
      okText="Save"
      width={600}
      okButtonProps={{
        loading: loading[userPermissionActions.setUserPermission.typePrefix] || false,
      }}
      centered
    >
      <Spin spinning={searchingPermissionGroup || false}>
        <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" preserve={false} className="group-permission-form">
          <div className="row">
            <div className="col-lg-12 px-2">
              <div className="d-flex flex-column border px-3 py-2" style={{ backgroundColor: '#F7F5F3', borderRadius: 8 }}>
                <span style={{ fontSize: 14, fontWeight: 500, color: '#1B1B1B' }}>{data?.name ?? ''}</span>
                <span style={{ fontSize: 12, color: '#616161' }}>{data?.email ?? ''}</span>
              </div>
            </div>
            <div className="col-lg-12 px-2 mt-3">
              <DebouncedSearchSelect
                showSuggest
                placeholder="Select permission group"
                value={permissionGroupID}
                loading={searchingPermissionGroup}
                onChange={(newValue) => setPermissionGroupID(Array.isArray(newValue) ? newValue[0] : (newValue ?? null))}
                getOptionsCallback={async (text) => {
                  setSearchingPermissionGroup(true);
                  const res = await api
                    .getGroupPermissions<IGroupPermissionResponse>({
                      keyword: text,
                      role: userInfo?.role!,
                    })
                    .finally(() => setSearchingPermissionGroup(false));

                  return res.data.data
                    .map((item) => ({
                      value: `${item.id}`,
                      label: `${item.name}`,
                    }))
                    ?.filter((item) => item?.label);
                }}
              />
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SetPermissionGroupModal;
