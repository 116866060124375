import Donutchart from "@/components/common/donutchart";
import { BriefCaseIcon, GlobeIcon, PlusIcon, Users3Icon } from "@/components/common/IconComponents";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useSelector } from "@/stores";
import {
  IConstants,
  ICountryConstant,
  IDashboardTopEmployee,
  IEmployee,
  IObject,
} from "@/types/app";
import {
  matchCountryCodeToFlag,
  matchCountryCodeToLabel,
  matchValueToLabel,
  sortedByCountryName,
} from "@/utils";
import { Col, Row, Space, Tag } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import axios from "axios";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";


const DashboardOffBoarding= () => {
  const {
    staticsEmployees,
    summaryEmployees,
    topEmployees,
    offBoardingEmployees,
  } = useSelector((state) => state.dashboard);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    if(offBoardingEmployees){
      const inReview=offBoardingEmployees?.totalEmployeesOffBoardingStatusInReview;
      const inProgress=offBoardingEmployees?.totalEmployeesOffBoardingStatusCommenced;
      const completed=offBoardingEmployees?.totalEmployeesOffBoardingStatusCompleted;
      const result=[inReview,inProgress,completed]
      setSeries(result)
    }
  }, [offBoardingEmployees]);


  return (
    <div>
      <p className="title">Offboarding</p>
      <Donutchart chartType="offboarding" total={offBoardingEmployees?.totalEmployees} label={['In Review', "In Progress", "Completed"]} colors={['#165DFF', '#27A376', '#FFD023']} series={series} />
    </div>
  );
};

export default DashboardOffBoarding;
