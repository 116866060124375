import { Link, useNavigate } from 'react-router-dom';
import { ILoginForm } from '@/types/app';
import { Button, Checkbox, Form, Input } from 'antd';
import { ROUTE_PATH } from '@/common/constants/routes';
import { useDispatch } from '@/stores';
import { authActions } from '@/stores/auth';
import { useState } from 'react';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isEmployee, setIsEmployee] = useState(false);

  const handleSubmitForm = (values: ILoginForm) => {
    const newValues = {
      ...values,
      redirectURL: `${window?.location?.origin}/reset-password`,
      isEmployee
    };

    dispatch(authActions.forgotPassword(newValues))
      ?.unwrap()
      .then(() => {
        navigate(-1);
      })
      .catch(console.log);
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      onFinish={(e) => handleSubmitForm(e)}
      className="auth bg-white"
    >
      <div className="auth-left">
        <div className="card-body">
          <div className="card-title">
            <h4 className="fw-bold mb-2 card-text text-capitalize">Forgot password</h4>
          </div>
          {isEmployee?
            <Form.Item label="Username" name="username" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Enter username" className="form-control w-100" />
            </Form.Item>
            :
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Enter email" type="email" className="form-control w-100" />
            </Form.Item>}
          <p
            style={{
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 600,
              // width: '50%',
            }}
            className="d-flex small"
          >
            <Checkbox id="isEmployee" className="mr-1" value={isEmployee} onChange={(e)=>setIsEmployee(e?.target?.checked)}/>
            <label htmlFor="isEmployee" className="mb-0">Are You Employee?</label>
          </p>

          <div className="small float-right mb-4" style={{ fontSize: '18px', fontWeight: 500, textDecoration: 'underline' }}>
            <Link to={ROUTE_PATH.LOGIN}>Login</Link>
          </div>

          <div className="form-footer">
            <Button type="primary" htmlType="submit" className="btn-block">
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className="login-img auth-right"></div>
    </Form>
  );
};

export default ForgotPassword;
