import { IPayroll, IUser } from '@/types/app';
import { Button, Form, Grid, Input, Popconfirm, Space, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { ROUTE_PATH } from '@/common/constants/routes';
import dayjs from 'dayjs';
import { dateFormat, dateTimeFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/stores';
import { authActions } from '@/stores/auth';
import { UserRole } from '@/common/constants/enums';
import { getBreakPoint, matchValueToLabel } from '@/utils';
import { payrollsActions } from '@/stores/payrolls';
import ModalPayroll from './components/ModalPayroll';
import { DeleteIcon, EditIcon, GrayEditIcon, SearchIcon } from '@/components/common/IconComponents';
import { SorterResult } from 'antd/es/table/interface';

const { useBreakpoint } = Grid;

const Payrolls = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { constants } = useSelector((state) => state.app);
  const { data, pagination, detail, modalPayrollVisibility, loading } = useSelector((state) => state.payrolls);

  const columns: ColumnsType<IPayroll> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      width: 50,
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              type="text"
              size="small"
              onClick={() => {
                dispatch(payrollsActions.onOpenModalPayroll(record));
              }}
            >
              <GrayEditIcon />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Delete this payroll"
            description="Are you sure to delete?"
            onConfirm={() => {
              dispatch(
                payrollsActions.deletePayroll({
                  id: record.id,
                }),
              )
                ?.unwrap()
                .then(() => {
                  dispatch(payrollsActions.getPayrolls({}));
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button type="text" size="small">
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: true,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      sorter: true,
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      sorter: true,
    },
  ];

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  useEffect(function () {
    dispatch(payrollsActions.getPayrolls({}));
  }, []);

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(payrollsActions.getPayrolls({ keyword }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      payrollsActions.getPayrolls({
        page,
        limit: pageSize,
      }),
    );
  };

  const handleSortAndPaginationChange = (pagination: TablePaginationConfig, sorter: SorterResult<IPayroll> | SorterResult<IPayroll>[]) => {
    dispatch(
      payrollsActions.getPayrolls({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
      }),
    );
  };

  const onSubmitPayroll = (values: IPayroll) => {
    console.log('*** test onSubmitPayroll values', values);
    if (values?.id && values?.id !== '') {
      dispatch(payrollsActions.updatePayroll(values)).then(() => {
        dispatch(payrollsActions.onCloseModalPayroll());
        dispatch(payrollsActions.getPayrolls({}));
      });
    } else {
      dispatch(payrollsActions.createPayroll(values)).then(() => {
        dispatch(payrollsActions.onCloseModalPayroll());
        dispatch(payrollsActions.getPayrolls({}));
      });
    }
  };

  return (
    <div className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[payrollsActions.getPayrolls.typePrefix]}>
        <div className="d-flex justify-content-between py-3">
          <div className="page-listing-title d-flex justify-content-between align-items-center mb-0">
            <span>Payroll List</span>
          </div>
          <Button type="primary" className="d-flex align-items-center" onClick={() => dispatch(payrollsActions.onOpenModalPayroll({}))}>
            <PlusOutlined /> Add New
          </Button>
        </div>

        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1200, y: 450 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>

        {modalPayrollVisibility && (
          <ModalPayroll
            title={detail?.id && detail?.id !== '' ? 'Update Payroll' : 'Add New Payroll'}
            confirmLoading={loading[payrollsActions.createPayroll.typePrefix] || loading[payrollsActions.updatePayroll.typePrefix]}
            open={modalPayrollVisibility}
            data={detail}
            destroyOnClose={true}
            onSubmit={onSubmitPayroll}
            onCancel={() => dispatch(payrollsActions.onCloseModalPayroll())}
            okText={detail?.id && detail?.id !== '' ? 'Update' : 'Add'}
          />
        )}
      </Spin>
    </div>
  );
};

export default Payrolls;
