import EmployeePersonalInfoForm from '@/components/Forms/EmployeePersonalInfoForm';
import EmployeeRegistrationInfoForm from '@/components/Forms/EmployeeRegistrationInfoForm';
import EmployeeRoleInfoForm from '@/components/Forms/EmployeeRoleInfoForm';
import UploadDocumentModal from '@/components/Modals/UploadDocumentModal';
import EmployeeDocuments from '@/components/Tables/EmployeeDocuments';
import EmployeeNotes from '@/components/Tables/EmployeeNotes';
import { useDispatch, useSelector } from '@/stores';
import { employeeActions } from '@/stores/employee';
import { IDocument, IEmployee } from '@/types/app';
import { Avatar, Button, Card, Col, Dropdown, Form, MenuProps, Popconfirm, Row, Space, Spin, Tabs, TabsProps, Tag } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DownCircleOutlined, DownOutlined, LeftOutlined, UserOutlined } from '@ant-design/icons';
import EmployeeDetailTabs from '../Employees/EmployeeDetailTabs';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  CallIcon,
  DeleteIcon,
  EditIcon,
  GrayEditIcon,
  LocationIcon,
  MailIcon,
  OffboardIcon,
} from '@/components/common/IconComponents';
import { ROUTE_PATH } from '@/common/constants/routes';
import RequestTerminationModal from '@/components/Modals/RequestTerminationModal';
import { appActions } from '@/stores/app';
import { employeePortalActions } from '@/stores/employeePortal';
import { matchValueToLabel } from '@/utils';

export const initialState: IEmployee = {
  title: 'mr',
  forename: '',
  surname: '',
  email: '',
  dob: '',
  // employeeNationality: "GB",
  // countryOfHire: "GB",
  needVisa: true,
  jobTitle: '',
  jobDescription: '',
  currency: 'EUR',
  payFrequency: 'hourly',
  startDate: 0,
  contractType: 'ongoing',
  requireHealthInsurance: true,
  address: '',
  phoneNumber: '',
  status: 'in_review',
  registrationStatus: 'in_review',
  siteLocation: '',
};

const EmployeePortal = (): JSX.Element => {
  // const { detail, loading, modalUploadDocumentVisibility, uploadDocumentType } = useSelector((state) => state.employee);
  const { loading, currentEmployee } = useSelector((state) => state.app);

  const { constants } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.profile);

  const { detail } = useSelector((state) => state.employeePortal);


  const [employee, setEmployee] = useState<IEmployee>(initialState);
  const [isRequestTerminationModalOpen, setIsRequestTerminationModalOpen] = useState(false);
  // const [activeKey, setActiveKey] = useState<number>(1);
  // const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);

  const [form] = Form.useForm();

  // useEffect(
  //   function () {
  //     if (detail) setEmployee(detail);
  //     else setEmployee(initialState);
  //   },
  //   [detail],
  // );

  // const handleSubmitDocument = (value: IDocument) => {
  //   const newValues = { ...value, employeeID: id, id, role: userInfo?.role };
  //   if (uploadDocumentType === 'timesheet') {
  //     dispatch(employeeActions.createTimesheetDocument(newValues as IDocument))
  //       .unwrap()
  //       .then(() => {
  //         dispatch(employeeActions.onCloseModalUploadDocument());
  //         dispatch(employeeActions.getEmployeeTimesheets({ id, role: userInfo?.role }));
  //       });
  //   } else if (uploadDocumentType === 'payslip') {
  //     dispatch(employeeActions.createPayslipDocument(newValues as IDocument))
  //       .unwrap()
  //       .then(() => {
  //         dispatch(employeeActions.onCloseModalUploadDocument());
  //         dispatch(employeeActions.getEmployeePayslips({ id, role: userInfo?.role }));
  //       });
  //   } else if (uploadDocumentType === 'others') {
  //     dispatch(employeeActions.createOthersDocument(newValues as IDocument))
  //       .unwrap()
  //       .then(() => {
  //         dispatch(employeeActions.onCloseModalUploadDocument());
  //         dispatch(employeeActions.getEmployeeOthers({ id, role: userInfo?.role }));
  //       });
  //   }
  // };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = () => {
    const pathNames = location?.pathname.split('/').filter((val) => val !== '');
    navigate(ROUTE_PATH.EMPLOYEE_LIST || '-1');
  };

  useEffect(() => {
    form.resetFields();
  }, [employee]);

  return (
    <div className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[employeePortalActions.getCurrentEmployee.typePrefix] || false}>
        <div className="d-flex justify-content-between align-items-center py-3">
          <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
            {/* <ArrowLeftIcon className="mr-2" onClick={navigateBack} /> */}
            <span>Employee Profile</span>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Card className="general-information border-0">
              <div className="d-flex flex-column align-items-center border-bottom pb-3">
                {/* <Avatar size={64} icon={<UserOutlined />}></Avatar> */}
                <p className="mb-0 title">
                  {detail?.name || (detail?.forename+ " " + detail?.surname)}
                </p>
                <p className="mb-2 subtitle">{detail?.jobTitle}</p>
                <Tag className={`rounded text-uppercase mr-0 ${detail?.status==="active"?'active-tag':'yellow-tag'}`}>{matchValueToLabel(constants?.statuses || [], detail?.status || '')}</Tag>
              </div>
              <div className="pt-3">
                <p className="mb-2 d-flex">
                  <MailIcon className="mr-2" />
                  <span className="text-break">{detail?.email}</span>
                </p>
                <p className="mb-2">
                  <CallIcon className="mr-2" />
                  {detail?.phoneNumber}
                </p>
                <p className="mb-3">
                  <LocationIcon className="mr-2" />
                  {detail?.employeeNationality?.name}
                </p>
                {/* <Dropdown placement="bottom" menu={{ items: actionMenu }} trigger={['click']} overlayClassName="" className="">
                  <div className="button-actions w-100">
                    Actions <ArrowDownIcon />
                  </div>
                </Dropdown> */}
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={16}>
            <EmployeeDetailTabs />
          </Col>
        </Row>

        {/* {modalUploadDocumentVisibility && (
          <UploadDocumentModal
            open={modalUploadDocumentVisibility}
            destroyOnClose={true}
            onCancel={() => dispatch(employeeActions.onCloseModalUploadDocument())}
            onSubmit={handleSubmitDocument}
            confirmLoading={
              loading[employeeActions?.createTimesheetDocument.typePrefix] ||
              loading[employeeActions?.createPayslipDocument.typePrefix] ||
              loading[employeeActions?.createOthersDocument.typePrefix]
            }
            hideCountryCode={true}
            hideDocumentType={true}
          />
        )}
        <RequestTerminationModal
          employeeId={id}
          isOpen={isRequestTerminationModalOpen}
          setIsOpen={(val: boolean) => setIsRequestTerminationModalOpen(val)}
        /> */}
      </Spin>
    </div>
  );
};

export default EmployeePortal;
