import { ICountryConstant, IDocument } from '@/types/app';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState, DragEvent, ChangeEvent, useEffect } from 'react';
import type { InputRef, ModalProps } from 'antd';

import { ReactComponent as UploadIcon } from '@/assets/images/new-icons/upload-icon-2.svg';

import { isVisible } from '@testing-library/user-event/dist/utils';
import { useDispatch, useSelector } from '@/stores';
import { importsActions } from '@/stores/imports';
import { downloadFileFromURL } from '@/utils';

interface IProps extends ModalProps {
  data?: IDocument | null;
  onSubmit: (data: IDocument) => void;
  countries?: ICountryConstant[];
  hideCountryCode?: boolean;
  hideDocumentType?: boolean;
}

const UploadFile = (props: IProps) => {
  const dispatch = useDispatch();
  const { data, onSubmit, title = 'Import Data', hideCountryCode, hideDocumentType, ...rest } = props;
  const [form] = Form.useForm();
  const [dataTemplate, setDataTemplate] = useState('');
  const [fileName, setFileName] = useState('');
  const [attachmentUpload, setAttachmentUpload] = useState<File | null>(null);
  const { constants, countries } = useSelector((state) => state?.app);
  const defaultCountryID = countries.find((item) => item?.countryCode === 'GB')?.id;

  const initState = {
    countryID: defaultCountryID,
  };

  const { link } = useSelector((state) => state?.imports);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue(initState);
      setAttachmentUpload(null);
      setFileName('');
    }
  }, [data]);

  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<InputRef>(null);

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
      form.setFieldValue('fileName', e.target.files)
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.input?.click?.();
  };

  function handleFile(files: any) {
    setAttachmentUpload(files[0]);
    setFileName(files[0].name);
  }

  useEffect(() => {
    dispatch(importsActions.getImportTemplate());
  }, []);

  const handleSubmitForm = (value: IDocument) => {
    let newValue = { ...value };

    if (attachmentUpload) {
      const isXlsx = attachmentUpload.name.endsWith('.xlsx');
      if (!isXlsx) {
        message.error('File need to be formatted with .xlsx');
        return;
      }

      newValue = { ...newValue, attachmentUpload };
    }

    onSubmit(newValue);
    form.resetFields();
  };

  return (
    <Modal
      {...rest}
      title={title}
      onOk={form.submit}
      okText="Upload"
      width={600}
      footer={[
        <button
          style={{
            padding: '8px 16px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            color: '#1E1D1D',
            borderRadius: '6px',
            border: '1px solid #E4E3E2',
            backgroundColor: 'white',
          }}
          onClick={rest.onCancel}
        >
          {' '}
          Cancel
        </button>,
        <button
          style={{
            fontSize: '14px',
            padding: '8px 16px',
            lineHeight: '20px',
            fontWeight: 600,
            marginLeft: '8px',
            border: '1px solid #1E1D1D',
            color: 'white',
            borderRadius: '6px',
            backgroundColor: '#1E1D1D',
          }}
          onClick={form.submit}
        >
          Upload
        </button>,
      ]}
    >
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <Form.Item
              label="Document"
              name="fileName"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <div id="form-file-upload" onDragEnter={handleDrag}>
                <Input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
                <label
                  id="label-file-upload"
                  style={{ backgroundColor: '#FBFAF8', border: '2px dashed #D3D1CF', borderRadius: '8px' }}
                  htmlFor="input-file-upload"
                  className={dragActive ? 'drag-active' : ''}
                >
                  <div>
                    <UploadIcon />
                    <p style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 500, color: '#8A8988' }}>
                      Drag and drop your file here or
                    </p>
                    <Button
                      className="upload-button"
                      onClick={onButtonClick}
                      style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 600, border: '1px solid #E4E3E2' }}
                    >
                      Click here
                    </Button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDrop={handleDrop}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                  ></div>
                )}
              </div>
              <div className="font-weight-bold mb-2">{fileName}</div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadFile;
