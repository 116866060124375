import {
  IConstant,
  IConstants,
  IDocument,
  IEmployeeNote,
  IEmployeeTimesheetsListTable,
  IResponsePagination,
  IUser,
} from "@/types/app";
import { Button, Form, Input, Space, Tooltip, Table, Tag, Grid } from "antd";
import { ColumnsType } from "antd/es/table";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewTimesheetsModal from "../Modals/ViewTimesheetsModal";
import {
  currencyFormat,
  dateFormat,
  dateTimeFormat,
  pageSizeOptions,
} from "@/common/constants/format";
import TableWithScrollButtons from "../Shared/TableWithScrollButtons";
import { downloadFileFromURL, exportToXLSX, getBreakPoint, matchValueToLabel } from "@/utils";
import dayjs from "dayjs";
import { FileTwoTone, DownloadOutlined } from "@ant-design/icons";
import UploadDocumentModal from "../Modals/UploadDocumentModal";
import { useDispatch, useSelector } from "@/stores";
import NewNoteModal from "../Modals/NewNoteModal";
import Paragraph from "antd/es/typography/Paragraph";
import { onboardingActions } from "@/stores/onboarding";
import { useParams } from "react-router-dom";
import { employeeActions } from "@/stores/employee";
import { DownloadIcon, EyeIcon, SearchIcon } from "../common/IconComponents";
import { isEmployee } from "@/utils/auth";

const { useBreakpoint } = Grid;

const EmployeeNotes = () => {
  const { detail, loading, notes, notePagination } = useSelector(
    (state) => state.employee
  );
  const { userInfo } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [form] = Form.useForm();
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');

  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [seeAllContentList, setSeeAllContentList] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement> | string) => {
    dispatch(
      employeeActions.getEmployeeNotes({
        role: userInfo?.role,
        id,
        keyword: e,
      })
    );
  };

  const columns: ColumnsType<IEmployeeNote> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      width: 120,
      align: 'center',
      render: (_, record) => (
        record?.attachment?.fileURL?<Space>
          <Tooltip title="Open Document">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(record?.attachment?.fileURL);
              }}
            >
              <EyeIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                if(record?.attachment?.fileURL)
                  downloadFileFromURL(record?.attachment?.fileURL, record?.attachment?.metadata?.name);
              }}
              // disabled={!record?.attachment?.fileURL}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Space>:null
      ),
    },
    {
      title: "File Name",
      dataIndex: ["attachment", "metadata", "name"],
      key: "fileName",
      align: "center",
      width: 150,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "client",
      align: "center",
      width: 150,
      render: (value, elm) => {
        return (
          <div
            className={`${
              seeAllContentList.includes(elm.id || "") ? "" : "text-truncate"
            } cursor-pointer`}
            onClick={() =>
              seeAllContentList.includes(elm.id || "")
                ? setSeeAllContentList(
                    seeAllContentList.filter((item) => item !== elm.id)
                  )
                : setSeeAllContentList([...seeAllContentList, elm.id || ""])
            }
          >
            {value}
          </div>
        );
      },
    },
    {
      title: "Time Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 150,

      render: (val) => dayjs.unix(val).format(dateTimeFormat),
    },
    {
      title: "Submitted By",
      dataIndex: "createdByUser",
      key: "createdByUser",
      align: "center",
      width: 150,
      render: (createdByUser: IUser) =>
        createdByUser?.name || createdByUser?.email,
    },
  ];

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      employeeActions.getEmployeeNotes({
        page,
        limit: pageSize,
        role: userInfo?.role,
        id,
      })
    );
  };

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  useEffect(() => {
    if (id)
      dispatch(
        employeeActions.getEmployeeNotes({
          role: userInfo?.role,
          id,
        })
      );
  }, [id]);

  return (
    <div>
      <div className="d-flex justify-content-between p-2">
        <Input
          prefix={<SearchIcon className="mr-0" />}
          placeholder="Search"
          onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
          className="w-100 mr-2 page-listing-search-input"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {isEmployee()?null:
        <Button type="primary" onClick={() => handleOpenModal(true)} className="ml-4 d-flex align-items-center">
          <PlusOutlined />
          Add Note
        </Button>}
      </div>
      <div className="bg-white rounded">
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={notes}
              size="small"
              scroll={{ x: 1200, y: 480 }}
              pagination={{
                onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                showSizeChanger: true,
                defaultCurrent: 1,
                total: notePagination?.total,
                position: ['bottomLeft'],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
            />
          </div>
        </div>
      </div>
      <NewNoteModal isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} detail={detail} role={userInfo?.role} type="employee" />
    </div>
  );
};

export default EmployeeNotes;
