import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ILoginForm } from '@/types/app';
import { Button, Checkbox, Form, Input } from 'antd';
import { ROUTE_PATH } from '@/common/constants/routes';
import { useDispatch } from '@/stores';
import { authActions } from '@/stores/auth';
import qs from 'qs';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { search } = useLocation();
  const token = qs.parse(search, {
    ignoreQueryPrefix: true,
  }).token;

  const handleSubmitForm = (values: ILoginForm) => {
    const newValues = { ...values, token };

    dispatch(authActions.resetPassword(newValues))
      ?.unwrap()
      .then((resp) => {
        dispatch(authActions.onLogout());
        navigate(ROUTE_PATH.LOGIN);
      })
      .catch(console.log);
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      onFinish={(e) => handleSubmitForm(e)}
      className="auth bg-white"
    >
      <div className="auth-left">
        <div className="card-body">
          <div className="card-title">
            <h4 className="fw-bold mb-2 card-text text-capitalize">Setup Account</h4>
          </div>
          <Form.Item
            name="newPassword"
            label={
              <div>
                <span>New password</span>
              </div>
            }
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            dependencies={['newPassword']}
            label={
              <div>
                <span>Confirm new password</span>
              </div>
            }
            rules={[
              {
                required: true,
                message: 'Please re-input your password',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The password that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className="small float-right mb-4" style={{ fontSize: '18px', fontWeight: 500, textDecoration: 'underline' }}>
            <Link to={ROUTE_PATH.LOGIN}>Login</Link>
          </div>

          <div className="form-footer">
            <Button type="primary" htmlType="submit" className="btn-block">
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className="login-img auth-right"></div>
    </Form>
  );
};

export default ResetPassword;
