import { useDispatch, useSelector } from '@/stores';
import { userPermissionActions } from '@/stores/userPermission';
import { IUser, PermissionFeature, PermissionProfile } from '@/types/app';
import { matchValueToLabel, openNotification } from '@/utils';
import type { CheckboxProps, ModalProps } from 'antd';
import { Checkbox, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';

interface IProps extends ModalProps {
  data?: Partial<IUser> | null;
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

const SetPermissionOnProfileModal = (props: IProps) => {
  const { data, onClose, onSubmitSuccess, ...rest } = props;

  const dispatch = useDispatch();
  const { constants } = useSelector((state) => state?.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.userPermission);

  const [permissionProfiles, setPermissionProfiles] = useState<PermissionProfile[]>([]);
  const [features, setFeatures] = useState<PermissionFeature[]>([]);


  useEffect(() => {
    if (data) {
      setPermissionProfiles(data?.userPermission?.permissions ?? []);
      setFeatures(data?.userPermission?.permissionFeatures ?? [])
    } else {
      setPermissionProfiles([]);
      setFeatures([]);
    }
  }, [data, constants?.permissionProfiles]);

  const handleSubmitForm = () => {
    dispatch(
      userPermissionActions.setUserPermission({
        userId: data?.id!,
        permissions: permissionProfiles,
        role: userInfo?.role!,
        permissionFeatures: features,
      }),
    )
      .unwrap()
      .then(() => {
        onSubmitSuccess?.();
        onClose?.();
      })
      .catch((res) => console.log('error', res.error));
  };

  const onToggleProfile =
    (feature: PermissionProfile): CheckboxProps['onChange'] =>
    (e) => {
      const oldFeatures = [...permissionProfiles];

      if (Array.isArray(oldFeatures)) {
        setPermissionProfiles(oldFeatures.includes(feature) ? oldFeatures.filter((item) => item !== feature) : [...oldFeatures, feature]);
      } else {
        setPermissionProfiles([feature]);
      }
    };

  const onToggleFeature =
    (feature: PermissionFeature): CheckboxProps['onChange'] =>
    (e) => {
      const oldFeatures = [...features];

      if (Array.isArray(oldFeatures)) {
        setFeatures(oldFeatures.includes(feature) ? oldFeatures.filter((item) => item !== feature) : [...oldFeatures, feature]);
      } else {
        setFeatures([feature]);
      }
  };

  return (
    <Modal
      {...rest}
      onCancel={() => onClose?.()}
      title="Set Permission on Profile"
      onOk={handleSubmitForm}
      okText="Save"
      width={600}
      okButtonProps={{
        loading: loading[userPermissionActions.setUserPermission.typePrefix] || false,
      }}
      centered
    >
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" preserve={false} className="group-permission-form">
        <div className="row">
          <div className="col-lg-12 px-2">
            <div className="d-flex flex-column border px-3 py-2" style={{ backgroundColor: '#F7F5F3', borderRadius: 8 }}>
              <span style={{ fontSize: 14, fontWeight: 500, color: '#1B1B1B' }}>{data?.name ?? ''}</span>
              <span style={{ fontSize: 12, color: '#616161' }}>{data?.email ?? ''}</span>
            </div>
          </div>
          {data?.role === 'employee' ? (
            <div className="col-lg-12 px-2 my-3">
              <div style={{ border: '1px solid #D3D1CF', borderRadius: 8 }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '60%' }}>Data</th>
                      <th>Can Edit/Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {constants?.permissionProfiles?.map((profile) => (
                      <tr key={profile.value}>
                        <td>{matchValueToLabel(constants?.permissionProfiles || [], profile.value)}</td>
                        <td>
                          <Checkbox checked={permissionProfiles?.includes(profile.value)} onChange={onToggleProfile(profile.value)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-lg-12 px-2 my-3">
              <div style={{ border: '1px solid #D3D1CF', borderRadius: 8 }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '60%' }}>Features</th>
                      <th>Can Edit/Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {constants?.permissionFeatures?.map((feature) => (
                      <tr key={feature.value}>
                        <td>{matchValueToLabel(constants?.permissionFeatures || [], feature.value)}</td>
                        <td>
                          <Checkbox checked={features?.includes(feature.value)} onChange={onToggleFeature(feature.value)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default SetPermissionOnProfileModal;
