import { pageSizeOptions } from '@/common/constants/format';
import { SearchIcon } from '@/components/common/IconComponents';
import AddEditGroupPermissionModal from '@/components/Modals/AddEditGroupPermission';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useDispatch, useSelector } from '@/stores';
import { groupPermissionActions } from '@/stores/groupPermission';
import { IDocument, IGroupPermission } from '@/types/app';
import { Button, Grid, Input, Spin } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getColumns } from './columns';
import { getBreakPoint } from '@/utils';

const { useBreakpoint } = Grid;

const UserGroupPermissionTable = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const dispatch = useDispatch();
  const { data, pagination, loading } = useSelector((state) => state?.groupPermission);
  const { userInfo } = useSelector((state) => state.auth);

  const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IGroupPermission | null>(null);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(
      groupPermissionActions.getGroupPermissions({
        role: userInfo?.role!,
      }),
    );
  }, [userInfo]);

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    dispatch(
      groupPermissionActions.getGroupPermissions({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        role: userInfo?.role!,
      }),
    );
  };

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(groupPermissionActions.getGroupPermissions({ keyword, role: userInfo?.role! }));
  };

  const handleOpenModalEdit = useCallback((item: IGroupPermission) => {
    setIsModalUpdateOpen(true);
    setSelectedItem(item);
  }, []);

  const handleConfirmDelete = useCallback(
    (item: IGroupPermission) => {
      dispatch(
        groupPermissionActions.deleteGroupPermission({
          id: item.id,
          role: userInfo?.role!,
        }),
      ).then(() => {
        dispatch(
          groupPermissionActions.getGroupPermissions({
            keyword: searchText,
            role: userInfo?.role!,
          }),
        );
      });
    },
    [dispatch, searchText, userInfo?.role],
  );

  const tableColumns = useMemo(
    () => getColumns({ onEdit: handleOpenModalEdit, onDelete: handleConfirmDelete, isMobile }),
    [handleOpenModalEdit, handleConfirmDelete, isMobile],
  );

  return (
    <>
      <div className="bg-white rounded">
        <div className="card-header d-flex justify-content-between">
          <Input
            prefix={<SearchIcon className="mr-0" />}
            placeholder="Search"
            onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
            className="w-100 mr-2 page-listing-search-input"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button className="d-flex align-items-center" type="primary" onClick={() => setIsModalAddOpen(true)}>
            Create Group
          </Button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Spin
              spinning={
                loading[groupPermissionActions.getGroupPermissions.typePrefix] ||
                loading[groupPermissionActions.deleteGroupPermission.typePrefix] ||
                false
              }
            >
              <TableWithScrollButtons
                columns={tableColumns}
                dataSource={data}
                size="small"
                scroll={{ x: 800 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
                // onChange={(pagination) => {
                //   onChangeFilter({
                //     page: pagination?.current,
                //     limit: pagination?.pageSize,
                //   });
                // }}
              />
            </Spin>
          </div>
        </div>
      </div>

      <AddEditGroupPermissionModal
        type="add"
        open={isModalAddOpen}
        destroyOnClose
        onClose={() => setIsModalAddOpen(false)}
        onSubmitSuccess={() => {
          dispatch(
            groupPermissionActions.getGroupPermissions({
              keyword: searchText,
              role: userInfo?.role!,
            }),
          );
        }}
      />
      <AddEditGroupPermissionModal
        type="edit"
        data={selectedItem}
        open={isModalUpdateOpen}
        destroyOnClose
        onClose={() => setIsModalUpdateOpen(false)}
        onSubmitSuccess={() => {
          dispatch(
            groupPermissionActions.getGroupPermissions({
              keyword: searchText,
              role: userInfo?.role!,
            }),
          );
        }}
      />
    </>
  );
};

export default UserGroupPermissionTable;
