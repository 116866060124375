import { useDispatch, useSelector } from '@/stores';
import { groupPermissionActions } from '@/stores/groupPermission';
import { GroupPermissionPayload, IGroupPermission, PermissionFeature } from '@/types/app';
import { matchValueToLabel, openNotification } from '@/utils';
import type { CheckboxProps, ModalProps } from 'antd';
import { Checkbox, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

interface IProps extends ModalProps {
  type?: 'add' | 'edit';
  data?: Partial<IGroupPermission> | null;
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

const AddEditGroupPermissionModal = (props: IProps) => {
  const { type, data, onClose, onSubmitSuccess, ...rest } = props;
  const [form] = Form.useForm<Omit<GroupPermissionPayload, 'features'>>();

  const dispatch = useDispatch();
  const { constants } = useSelector((state) => state?.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.groupPermission);

  const [features, setFeatures] = useState<PermissionFeature[]>([]);

  useEffect(() => {
    if (type === 'edit' && data) {
      form.setFieldsValue({
        name: data.name ?? '',
        description: data.description ?? '',
      });
      setFeatures(data.features ?? constants?.permissionFeatures?.map((item) => item.value) ?? []);
    } else {
      form.setFieldsValue({
        name: '',
        description: '',
      });
      setFeatures([]);
    }
  }, [type, data, constants?.permissionFeatures]);

  const handleSubmitForm = (formValues: Omit<GroupPermissionPayload, 'features'>) => {
    if (type === 'add') {
      dispatch(
        groupPermissionActions.createGroupPermission({
          ...formValues,
          features,
          role: userInfo?.role!,
        }),
      )
        .unwrap()
        .then(() => {
          onSubmitSuccess?.();
          onClose?.();
        })
        .catch((res) => openNotification('error', res.error));
    } else if (data?.id) {
      dispatch(
        groupPermissionActions.updateGroupPermission({
          ...formValues,
          features,
          role: userInfo?.role!,
          id: data.id,
        }),
      )
        .unwrap()
        .then(() => {
          onSubmitSuccess?.();
          onClose?.();
        })
        .catch((res) => openNotification('error', res.error));
    }
  };

  const onToggleFeature =
    (feature: PermissionFeature): CheckboxProps['onChange'] =>
    (e) => {
      const oldFeatures = [...features];

      if (Array.isArray(oldFeatures)) {
        setFeatures(oldFeatures.includes(feature) ? oldFeatures.filter((item) => item !== feature) : [...oldFeatures, feature]);
      } else {
        setFeatures([feature]);
      }
    };

  return (
    <Modal
      {...rest}
      onCancel={() => onClose?.()}
      title={type === 'add' ? 'Add Group Permission' : 'Group Permission'}
      onOk={form.submit}
      okText="Save"
      width={600}
      okButtonProps={{
        loading:
          loading[groupPermissionActions.createGroupPermission.typePrefix] ||
          loading[groupPermissionActions.updateGroupPermission.typePrefix] ||
          false,
      }}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
        initialValues={{
          name: data?.name ?? '',
          description: data?.description ?? '',
        }}
        preserve={false}
        className="group-permission-form"
      >
        <div className="row">
          <div className="col-lg-12">
            <Form.Item label="Group Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Enter group name" maxLength={2000} />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item label="Description" name="description">
              <Input.TextArea rows={4} className="w-100" placeholder="Enter description" maxLength={2000} />
            </Form.Item>
          </div>
          <div className="col-lg-12 px-2">
            <div style={{ border: '1px solid #D3D1CF', borderRadius: 8 }}>
              <table>
                <thead>
                  <tr>
                    <th>Features</th>
                    <th>Can Edit/Access</th>
                  </tr>
                </thead>
                <tbody>
                  {constants?.permissionFeatures?.map((feature) => (
                    <tr key={feature.value}>
                      <td>{matchValueToLabel(constants?.permissionFeatures || [], feature.value)}</td>
                      <td>
                        <Checkbox checked={features?.includes(feature.value)} onChange={onToggleFeature(feature.value)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditGroupPermissionModal;
