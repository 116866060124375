import EmployeePersonalInfoForm from '@/components/Forms/EmployeePersonalInfoForm';
import EmployeeRegistrationInfoForm from '@/components/Forms/EmployeeRegistrationInfoForm';
import EmployeeRoleInfoForm from '@/components/Forms/EmployeeRoleInfoForm';
import UploadDocumentModal from '@/components/Modals/UploadDocumentModal';
import EmployeeDocuments from '@/components/Tables/EmployeeDocuments';
import EmployeeNotes from '@/components/Tables/EmployeeNotes';
import { useDispatch, useSelector } from '@/stores';
import { employeeActions } from '@/stores/employee';
import { IDocument, IEmployee, PermissionProfile } from '@/types/app';
import { Button, Empty, Form, Spin, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import GeneralInfoTab from './tabs/GeneralInfoTab';
import RoleInfoTab from './tabs/RoleInfoTab';
import PayrollTab from './tabs/PayrollTab';
import RegistrationInfoTab from './tabs/RegistrationInfoTab';
import BankTab from './tabs/BankTab';
import { isEmployee } from '@/utils/auth';
import { employeePortalActions } from '@/stores/employeePortal';

export const initialState: IEmployee = {
  title: 'mr',
  forename: '',
  surname: '',
  email: '',
  dob: '',
  // employeeNationality: "GB",
  // countryOfHire: "GB",
  needVisa: true,
  jobTitle: '',
  jobDescription: '',
  currency: 'EUR',
  payFrequency: 'hourly',
  startDate: 0,
  contractType: 'ongoing',
  requireHealthInsurance: true,
  address: '',
  phoneNumber: '',
  status: 'in_review',
  registrationStatus: 'in_review',
  siteLocation: '',
};

type EmployeeTab = Required<TabsProps>['items'][number] & { permission?: PermissionProfile };

const EmployeeDetailTabs = (props: any): JSX.Element => {
  const { create } = props;
  const { detail, timesheetDocuments, payslipDocuments, othersDocuments, guideDocuments } = useSelector((state) => state.employee);
  const {
    detail: employeePortalDetail,
    timesheetDocuments: employeePortalTimesheetDocuments,
    payslipDocuments: employeePortalPayslipDocuments,
    othersDocuments: employeePortalOthersDocuments,
    guideDocuments: employeePortalGuideDocuments,
  } = useSelector((state) => state.employeePortal);

  const { id } = useParams();

  const items: EmployeeTab[] = [
    {
      key: '1',
      label: (
        <Link to={`?tab=1`} className="py-4">
          Personal Info
        </Link>
      ),
      children: <GeneralInfoTab detail={isEmployee() ? employeePortalDetail : detail} />,
      permission: 'GeneralInfo',
    },
    {
      key: '2',
      label: (
        <Link to={`?tab=2`} className="py-4">
          Role Info
        </Link>
      ),
      children: <RoleInfoTab detail={isEmployee() ? employeePortalDetail : detail} />,
      permission: 'RoleInfo',
    },
    {
      key: '3',
      label: (
        <Link to={`?tab=3`} className="py-4">
          Bank
        </Link>
      ),
      children: <BankTab detail={isEmployee() ? employeePortalDetail : detail} />,
      permission: 'Bank',
    },
    {
      key: '4',
      label: (
        <Link to={`?tab=4`} className="py-4">
          Payroll
        </Link>
      ),
      children: <PayrollTab detail={isEmployee() ? employeePortalDetail : detail} />,
      permission: 'Payroll',
    },
    {
      key: '5',
      label: (
        <Link to={`?tab=5`} className="py-4">
          Documents
        </Link>
      ),
      children: (
        <EmployeeDocuments
          data={isEmployee() ? employeePortalGuideDocuments : guideDocuments}
          documentType="guide"
          onImport={async () => {
            await dispatch(employeeActions.onOpenModalUploadDocument('guide'));
            await dispatch(employeeActions.getEmployeeGuides({ id, role: user?.role }));
          }}
        />
      ),
      permission: 'DocumentUpload',
    },
    ...(isEmployee()
      ? []
      : ([
          {
            key: '6',
            label: (
              <Link to={`?tab=6`} className="py-4">
                Timesheets
              </Link>
            ),
            children: (
              <EmployeeDocuments
                documentType="timesheets"
                data={isEmployee() ? employeePortalTimesheetDocuments : timesheetDocuments}
                onImport={async () => {
                  await dispatch(employeeActions.onOpenModalUploadDocument('timesheet'));
                  await dispatch(
                    employeeActions.getEmployeeTimesheets({
                      id,
                      role: user?.role,
                    }),
                  );
                }}
              />
            ),
            permission: 'TimeSheetUpload',
          },
          {
            key: '7',
            label: (
              <Link to={`?tab=7`} className="py-4">
                Notes
              </Link>
            ),
            children: <EmployeeNotes />,
            permission: 'NoteUpload',
          },
        ] satisfies EmployeeTab[])),
    {
      key: '8',
      label: (
        <Link to={`?tab=8`} className="py-4">
          Registration Info
        </Link>
      ),
      children: <RegistrationInfoTab detail={isEmployee() ? employeePortalDetail : detail} />,
      permission: 'RegistrationInfo',
    },
    {
      key: '9',
      label: (
        <Link to={`?tab=9`} className="py-4">
          Payslips
        </Link>
      ),
      children: (
        <EmployeeDocuments
          documentType="payslips"
          data={isEmployee() ? employeePortalPayslipDocuments : payslipDocuments}
          onImport={async () => {
            await dispatch(employeeActions.onOpenModalUploadDocument('payslip'));
            await dispatch(employeeActions.getEmployeePayslips({ id, role: user?.role }));
          }}
        />
      ),
      permission: 'PaySlips',
    },
    ...(isEmployee()
      ? []
      : ([
          {
            key: '10',
            label: (
              <Link to={`?tab=10`} className="py-4">
                Others
              </Link>
            ),
            children: (
              <EmployeeDocuments
                documentType="others"
                data={isEmployee() ? employeePortalOthersDocuments : othersDocuments}
                onImport={async () => {
                  await dispatch(employeeActions.onOpenModalUploadDocument('others'));
                  await dispatch(employeeActions.getEmployeeOthers({ id, role: user?.role }));
                }}
              />
            ),
            permission: 'DocumentUpload',
          },
        ] satisfies EmployeeTab[])),
  ];
  const defaultActiveTab = items?.filter((item) => window?.location?.search.includes(item.key))?.[0]?.key || '1';

  const dispatch = useDispatch();

  const { countries } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.profile);

  const [employee, setEmployee] = useState<IEmployee>(initialState);
  // const [activeKey, setActiveKey] = useState<number>(1);
  // const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<number>(Number(defaultActiveTab) || 1);
  const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);

  const [form] = Form.useForm();

  useEffect(
    function () {
      if (!isEmployee() && id && user?.role) {
        dispatch(employeeActions.getEmployee({ id: id, role: user?.role }));
      }
    },
    [id, user],
  );

  useEffect(
    function () {
      if(user?.role){
        if(isEmployee()){
          if (activeKey === 5) {
            dispatch(employeePortalActions.getCurrentEmployeeGuides({ id: user?.id }));
          }
          if (activeKey === 6) {
            dispatch(
              employeePortalActions.getCurrentEmployeeTimesheets({
                id: user?.id
              }),
            );
          }
          if (activeKey === 9) {
            dispatch(employeePortalActions.getCurrentEmployeePayslips({ id: user?.id }));
          }
          if (activeKey === 10) {
            dispatch(employeePortalActions.getCurrentEmployeeOthers({ id: user?.id }));
          }
        }
        else if(id) {
          if (activeKey === 5) {
            dispatch(employeeActions.getEmployeeGuides({ id: id, role: user?.role }));
          }
          if (activeKey === 6) {
            dispatch(
              employeeActions.getEmployeeTimesheets({
                id: id,
                role: user?.role,
              }),
            );
          }
          if (activeKey === 9) {
            dispatch(employeeActions.getEmployeePayslips({ id: id, role: user?.role }));
          }
          if (activeKey === 10) {
            dispatch(employeeActions.getEmployeeOthers({ id: id, role: user?.role }));
          }
        }
      }
    },
    [activeKey, user],
  );

  useEffect(() => {
    if (!create) {
      if (detail) setEmployee(detail);
    } else setEmployee(initialState);
  }, [detail]);


  const transformBeforeSubmit = (e: IEmployee) => {
    return {
      ...e,
      id,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
      name: e.forename?.trim?.() + ' ' + e.surname?.trim?.(),
      role: user?.role,
      startDate: e.startDate ? dayjs(e.startDate).unix() : 0,
      endDate: e.endDate ? dayjs(e.endDate).unix() : 0,
      // dob: e.dob ? dayjs(e.dob).unix() : 0,
    };
  };

  const handleSubmitForm = (e: IEmployee) => {
    const result = transformBeforeSubmit({ ...employee, ...e });
    dispatch(employeeActions.updateEmployee(result));
  };

  useEffect(() => {
    form.resetFields();
  }, [employee]);

  useEffect(() => {
    if (activeKey > 3) setShowSaveBtn(false);
    else setShowSaveBtn(true);
  }, [activeKey]);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      initialValues={employee}
      onFinish={handleSubmitForm}
      form={form}
    >
      {isEmployee() && !user?.userPermission?.permissions?.length ? (
        <Empty description="You don't have permission to view this section" />
      ) : (
        <Tabs
          defaultActiveKey={defaultActiveTab}
          items={items.filter((item) =>
            isEmployee() ? (item.permission ? user?.userPermission?.permissions?.includes(item.permission) : true) : true,
          )}
          onChange={(e) => setActiveKey(Number(e))}
          className="bg-white rounded p-3"
          destroyInactiveTabPane
        />
      )}
      {/* {showSaveBtn ? (
        <div className="card-footer col-md-12 text-right">
          <Button
            type="primary"
            htmlType="submit"
            className="btn btn-primary"
          >
            Save
          </Button>
        </div>
      ) : null} */}
    </Form>
  );
};

export default EmployeeDetailTabs;
