import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IDocument,
  IEmployee,
  IEmployeeResponse,
  IPagination,
  IResponsePagination,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface IEmployeeState {
  data?: IEmployee[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IEmployee;
  othersDocuments: IResponsePagination<IDocument>;
}

export const initialState: IEmployeeState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  othersDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
};

const getNewHires = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/getNewHires`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getNewHires<IEmployeeResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getNewHire = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/getNewHire`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getNewHire<{ data: IEmployee }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createNewHire = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/createNewHire`,
  async (form: IEmployee, { rejectWithValue }) => {
    try {
      const data = await api.createNewHire<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateNewHire = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/updateNewHire`,
  async (form: IEmployee, { rejectWithValue }) => {
    try {
      const data = await api.updateNewHire<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteNewHire = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/deleteNewHire`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.deleteNewHire<{ data: IEmployee }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const onboardNewHire = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/onboardNewHire`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.onboardNewHire<{ data: IEmployee }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getEmployeeOthers = createAsyncThunk(
  `${SLICE_NAME.NEW_HIRE}/getEmployeeOthers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeeOthers<IResponsePagination<IDocument>>(
        query
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const sendProfileEmployee = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/sendProfileEmployee`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.sendProfileEmployee<{ data: IEmployee }>(
        query
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const mapFromResponse = (response: IEmployee) => {
  return {
    ...response,
    // dob: response.dob ? response.dob * 1000 : 0,
    startDate: response.startDate ? response.startDate * 1000 : 0,
    endDate: response.endDate ? response.endDate * 1000 : 0,
  };
};

const newHireSlice = createSlice({
  name: SLICE_NAME.NEW_HIRE,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewHires.fulfilled, (state, { payload }) => {
      state.loading[getNewHires.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getNewHires.pending, (state, { payload }) => {
      state.loading[getNewHires.typePrefix] = true;
    });
    builder.addCase(getNewHires.rejected, (state, { payload }) => {
      state.loading[getNewHires.typePrefix] = false;
    });
    builder.addCase(getNewHire.fulfilled, (state, { payload }) => {
      state.loading[getNewHire.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getNewHire.pending, (state, { payload }) => {
      state.loading[getNewHire.typePrefix] = true;
    });
    builder.addCase(getNewHire.rejected, (state, { payload }) => {
      state.loading[getNewHire.typePrefix] = false;
    });
    builder.addCase(createNewHire.fulfilled, (state, { payload }) => {
      navigateTo(ROUTE_PATH.NEW_HIRE);
      openNotification("success", "New hire requested!");
    });
    builder.addCase(createNewHire.pending, (state, { payload }) => {
      state.loading[createNewHire.typePrefix] = true;
    });
    builder.addCase(createNewHire.rejected, (state, { payload }) => {
      console.log("**** test error payload", payload);
      // openNotification("error", "Request a new hire failed!");
      state.loading[createNewHire.typePrefix] = false;
    });
    builder.addCase(updateNewHire.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.NEW_HIRE);
      state.loading[updateNewHire.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateNewHire.pending, (state, { payload }) => {
      state.loading[updateNewHire.typePrefix] = true;
    });
    builder.addCase(updateNewHire.rejected, (state, { payload }) => {
      state.loading[updateNewHire.typePrefix] = false;
    });
    builder.addCase(deleteNewHire.fulfilled, (state, { payload }) => {
      openNotification(
        "success",
        `${payload.data.data?.name || (payload.data.data?.forename+ " " + payload.data.data?.surname)} deleted successfully!`
      );
      state.loading[deleteNewHire.typePrefix] = false;
    });
    builder.addCase(deleteNewHire.pending, (state, { payload }) => {
      state.loading[deleteNewHire.typePrefix] = true;
    });
    builder.addCase(deleteNewHire.rejected, (state, { payload }) => {
      openNotification("error", "Delete failed!");
      state.loading[deleteNewHire.typePrefix] = false;
    });
    builder.addCase(onboardNewHire.fulfilled, (state, { payload }) => {
      openNotification(
        "success",
        `${payload.data.data?.name || (payload.data.data?.forename+ " " + payload.data.data?.surname)} progressed to onboarding!`
      );
      state.loading[onboardNewHire.typePrefix] = false;
    });
    builder.addCase(onboardNewHire.pending, (state, { payload }) => {
      state.loading[onboardNewHire.typePrefix] = true;
    });
    builder.addCase(onboardNewHire.rejected, (state, { payload }) => {
      openNotification("error", "Onboard failed!");
      state.loading[onboardNewHire.typePrefix] = false;
    });
    builder.addCase(getEmployeeOthers.fulfilled, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = false;
      state.othersDocuments.data = payload.data.data;
      state.othersDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployeeOthers.pending, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = true;
    });
    builder.addCase(getEmployeeOthers.rejected, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = false;
    });
    builder.addCase(sendProfileEmployee.fulfilled, (state, { payload }) => {
      openNotification('success', 'Send profile successfully!');
      state.loading[sendProfileEmployee.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(sendProfileEmployee.pending, (state, { payload }) => {
      state.loading[sendProfileEmployee.typePrefix] = true;
    });
    builder.addCase(sendProfileEmployee.rejected, (state, { payload }) => {
      openNotification('error', 'Send profile failed!');
      state.loading[sendProfileEmployee.typePrefix] = false;
    });
    
  },
});

export const newHireReducer = newHireSlice.reducer;
export const newHireCaseReducers = newHireSlice.caseReducers;
export const newHireActions = {
  ...newHireSlice.actions,
  getNewHires,
  getNewHire,
  createNewHire,
  updateNewHire,
  deleteNewHire,
  onboardNewHire,
  getEmployeeOthers,
  sendProfileEmployee,
};
