import { useDispatch, useSelector } from '@/stores';
import { employeeActions } from '@/stores/employee';
import { employeePortalActions } from '@/stores/employeePortal';
import { IEmployee } from '@/types/app';
import { isEmployee } from '@/utils/auth';
import { Button, Card, Col, Empty, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import BankCard from './BankCard';
import AddEditBankModal from '@/components/Modals/AddEditBankModal';

const BankTab = ({ detail }: { detail?: IEmployee }) => {
  const dispatch = useDispatch();
  const { banks, loading } = useSelector((state) => state.employee);
  const { banks: employeePortalBanks, loading: employeePortalLoading } = useSelector((state) => state.employeePortal);

  const [openAddModal, setOpenAddModal] = useState(false);

  const fetchBanks = () => {
    if (detail?.id) {
      if (isEmployee()) {
        dispatch(employeePortalActions.getCurrentEmployeeBanks({}));
      } else dispatch(employeeActions.getSharedEmployeeBanks({ id: detail.id }));
    }
  };

  useEffect(() => {
    fetchBanks();
  }, [detail]);

  return (
    <div className="d-flex flex-column">
      <Spin
        tip="Loading"
        size="large"
        spinning={loading[employeeActions.getSharedEmployeeBanks.typePrefix]
          || employeePortalLoading[employeePortalActions.getCurrentEmployeeBanks.typePrefix]
          || false}>
          {banks.data.length > 0 || employeePortalBanks?.data?.length > 0 ? (
            <BankCard bank={isEmployee()?employeePortalBanks.data[0]:banks.data[0]} onEditSuccess={fetchBanks} />
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Empty description="No Banks Found" />
              {isEmployee() ? null : (
                <div className="d-flex justify-content-start mb-3">
                  <Button type="primary" onClick={() => setOpenAddModal(true)}>
                    Add Bank
                  </Button>
                </div>
              )}
            </div>
          )}

        <AddEditBankModal type="add" open={openAddModal} onClose={() => setOpenAddModal(false)} onSubmitSuccess={fetchBanks} />
      </Spin>
    </div>
  );
};

export default BankTab;
