import Icon from "@ant-design/icons";
import { ReactComponent as ArrowLeft } from '@/assets/images/new-icons/arrow-left.svg';
import { ReactComponent as ArrowDown } from '@/assets/images/new-icons/arrow-down.svg';
import { ReactComponent as Search } from '@/assets/images/new-icons/search.svg';
import { ReactComponent as Eye } from '@/assets/images/new-icons/eye.svg';
import { ReactComponent as Delete } from '@/assets/images/new-icons/delete.svg';
import { ReactComponent as LoginAs } from '@/assets/images/new-icons/login-as.svg';
import { ReactComponent as Edit } from '@/assets/images/new-icons/edit.svg';
import { ReactComponent as GrayEdit } from '@/assets/images/new-icons/gray-edit.svg';
import { ReactComponent as Users } from '@/assets/images/new-icons/users.svg';
import { ReactComponent as Expand } from '@/assets/images/new-icons/expand.svg';
import { ReactComponent as Setting } from '@/assets/images/new-icons/setting.svg';
import { ReactComponent as Dashboard } from '@/assets/images/new-icons/dashboard.svg';
import { ReactComponent as Chat } from '@/assets/images/new-icons/chat.svg';
import { ReactComponent as DocumentGuide } from '@/assets/images/new-icons/document-guide.svg';
import { ReactComponent as Invoice } from '@/assets/images/new-icons/invoice.svg';
import { ReactComponent as Report } from '@/assets/images/new-icons/report.svg';
import { ReactComponent as Globe } from '@/assets/images/new-icons/globe.svg';
import { ReactComponent as BriefCase } from '@/assets/images/new-icons/briefcase.svg';
import { ReactComponent as Plus } from '@/assets/images/new-icons/plus.svg';
import { ReactComponent as Users3 } from '@/assets/images/new-icons/users3.svg';
import { ReactComponent as Mail } from '@/assets/images/new-icons/mail.svg';
import { ReactComponent as Location } from '@/assets/images/new-icons/location.svg';
import { ReactComponent as Call } from '@/assets/images/new-icons/call.svg';
import { ReactComponent as TrendingUp } from '@/assets/images/new-icons/trending-up.svg';
import { ReactComponent as TrendingDown } from '@/assets/images/new-icons/trending-down.svg';
import { ReactComponent as Download } from '@/assets/images/new-icons/download.svg';
import { ReactComponent as Offboard } from '@/assets/images/new-icons/offboard.svg';
import { ReactComponent as Export } from '@/assets/images/new-icons/export.svg';
import { ReactComponent as Send } from '@/assets/images/new-icons/send.svg';
import { ReactComponent as UpdateStatus } from '@/assets/images/new-icons/update-status.svg';
import { ReactComponent as Flag } from '@/assets/images/new-icons/flag.svg';
import { ReactComponent as Client } from '@/assets/images/new-icons/client.svg';



export const ArrowLeftIcon = (props: any) => <Icon {...props} component={() => <ArrowLeft />} />
export const ArrowDownIcon = (props: any) => <Icon {...props} component={() => <ArrowDown />} />
export const SearchIcon = (props: any) => <Icon {...props} component={() => <Search />} />
export const EyeIcon = (props: any) => <Icon {...props} component={() => <Eye />} />
export const DeleteIcon = (props: any) => <Icon {...props} component={() => <Delete />} />
export const LoginAsIcon = (props: any) => <Icon {...props} component={() => <LoginAs />} />
export const EditIcon = (props: any) => <Icon {...props} component={() => <Edit />} />
export const GrayEditIcon = (props: any) => <Icon {...props} component={() => <GrayEdit />} />
export const UsersIcon = (props: any) => <Icon {...props} component={() => <Users />} />
export const SettingIcon = (props: any) => <Icon {...props} component={() => <Setting />} />
export const DashboardIcon = (props: any) => <Icon {...props} component={() => <Dashboard />} />
export const ChatIcon = (props: any) => <Icon {...props} component={() => <Chat />} />
export const DocumentGuideIcon = (props: any) => <Icon {...props} component={() => <DocumentGuide />} />
export const InvoiceIcon = (props: any) => <Icon {...props} component={() => <Invoice />} />
export const ReportIcon = (props: any) => <Icon {...props} component={() => <Report />} />
export const ExpandIcon = (props: any) => <Icon {...props} component={() => <Expand />} />
export const GlobeIcon = (props: any) => <Icon {...props} component={() => <Globe />} />
export const BriefCaseIcon = (props: any) => <Icon {...props} component={() => <BriefCase />} />
export const PlusIcon = (props: any) => <Icon {...props} component={() => <Plus />} />
export const Users3Icon = (props: any) => <Icon {...props} component={() => <Users3 />} />
export const MailIcon = (props: any) => <Icon {...props} component={() => <Mail />} />
export const LocationIcon = (props: any) => <Icon {...props} component={() => <Location />} />
export const CallIcon = (props: any) => <Icon {...props} component={() => <Call />} />
export const TrendingUpIcon = (props: any) => <Icon {...props} component={() => <TrendingUp />} />
export const TrendingDownIcon = (props: any) => <Icon {...props} style={{transform: 'scaleY(-1)'}} component={() => <TrendingDown />} />
export const DownloadIcon = (props: any) => <Icon {...props} component={() => <Download />} />
export const OffboardIcon = (props: any) => <Icon {...props} component={() => <Offboard />} />
export const ExportIcon = (props: any) => <Icon {...props} component={() => <Export />} />
export const SendIcon = (props: any) => <Icon {...props} component={() => <Send />} />
export const UpdateStatusIcon = (props: any) => <Icon {...props} component={() => <UpdateStatus />} />
export const FlagIcon = (props: any) => <Icon {...props} component={() => <Flag />} />
export const ClientIcon = (props: any) => <Icon {...props} component={() => <Client />} />








