import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  DocumentType,
  IBank,
  IDocument,
  IEmployee,
  IEmployeeNote,
  IEmployeeNoteResponse,
  IEmployeeResponse,
  IPagination,
  IResponsePagination,
} from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';

export interface IEmployeeState {
  data?: IEmployee[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IEmployee;
  currentEmployee?: IEmployee;
  modalUploadDocumentVisibility?: boolean;
  uploadDocumentType?: DocumentType;

  banks: IResponsePagination<IBank>;

  timesheetDocuments: IResponsePagination<IDocument>;
  payslipDocuments: IResponsePagination<IDocument>;
  othersDocuments: IResponsePagination<IDocument>;
  guideDocuments: IResponsePagination<IDocument>;
  notes?: IEmployeeNote[];
  notePagination?: IPagination;
}

export const initialState: IEmployeeState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  modalUploadDocumentVisibility: false,
  uploadDocumentType: undefined,
  banks: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  timesheetDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  payslipDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  othersDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  guideDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
};

const getCurrentEmployee = createAsyncThunk(`${SLICE_NAME.APP}/getCurrentEmployee`, async () => {
  const data = await api.getCurrentEmployee<{ data: IEmployee }>();
  return data;
});

const getCurrentEmployeeBanks = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeeBanks`,
  async (query: { id?: string }, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeeBanks<IResponsePagination<IBank>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const getCurrentEmployeeTimesheets = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeeTimesheets`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeeTimesheets<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getCurrentEmployeePayslips = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeePayslips`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeePayslips<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getCurrentEmployeeOthers = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeeOthers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeeOthers<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);


const getCurrentEmployeeGuides = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeeGuides`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeeGuides<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getCurrentEmployeeNotes = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE_PORTAL}/getCurrentEmployeeNotes`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCurrentEmployeeNotes<IEmployeeNoteResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IEmployee) => {
  return {
    ...response,
    // dob: response.dob ? response.dob * 1000 : 0,
    startDate: response.startDate ? response.startDate * 1000 : 0,
    endDate: response.endDate ? response.endDate * 1000 : 0,
    anticipatedEndDate: response.anticipatedEndDate ? response.anticipatedEndDate * 1000 : 0,
  };
};

const employeePortalSlice = createSlice({
  name: SLICE_NAME.EMPLOYEE_PORTAL,
  initialState: initialState,
  reducers: {
    onOpenModalUploadDocument(state, { payload }) {
      state.modalUploadDocumentVisibility = true;
      state.uploadDocumentType = payload;
    },
    onCloseModalUploadDocument(state) {
      state.modalUploadDocumentVisibility = false;
      state.uploadDocumentType = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentEmployee.pending, (state, { payload }) => {
      state.loading[getCurrentEmployee.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployee.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployee.typePrefix] = false;
    });
    builder.addCase(getCurrentEmployee.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployee.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getCurrentEmployeeTimesheets.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeeTimesheets.typePrefix] = false;
      console.log('**** test getCurrentEmployeeTimesheets payload', payload?.data);
      state.timesheetDocuments.data = payload.data.data;
      state.timesheetDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeeTimesheets.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeeTimesheets.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeeTimesheets.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeeTimesheets.typePrefix] = false;
    });

    builder.addCase(getCurrentEmployeeBanks.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeeBanks.typePrefix] = false;
      console.log('**** test getCurrentEmployeeBanks payload', payload?.data);
      state.banks.data = payload.data.data;
      state.banks.pagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeeBanks.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeeBanks.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeeBanks.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeeBanks.typePrefix] = false;
    });

    builder.addCase(getCurrentEmployeePayslips.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeePayslips.typePrefix] = false;
      state.payslipDocuments.data = payload.data.data;
      state.payslipDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeePayslips.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeePayslips.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeePayslips.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeePayslips.typePrefix] = false;
    });
    builder.addCase(getCurrentEmployeeOthers.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeeOthers.typePrefix] = false;
      state.othersDocuments.data = payload.data.data;
      state.othersDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeeOthers.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeeOthers.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeeOthers.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeeOthers.typePrefix] = false;
    });
    builder.addCase(getCurrentEmployeeGuides.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeeGuides.typePrefix] = false;
      state.guideDocuments.data = payload.data.data;
      state.guideDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeeGuides.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeeGuides.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeeGuides.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeeGuides.typePrefix] = false;
    });
    builder.addCase(getCurrentEmployeeNotes.fulfilled, (state, { payload }) => {
      state.loading[getCurrentEmployeeNotes.typePrefix] = false;
      state.notes = payload.data.data;
      state.notePagination = payload.data.pagination;
    });
    builder.addCase(getCurrentEmployeeNotes.pending, (state, { payload }) => {
      state.loading[getCurrentEmployeeNotes.typePrefix] = true;
    });
    builder.addCase(getCurrentEmployeeNotes.rejected, (state, { payload }) => {
      state.loading[getCurrentEmployeeNotes.typePrefix] = false;
    });
  },
});

export const employeePortalReducer = employeePortalSlice.reducer;
export const employeePortalCaseReducers = employeePortalSlice.caseReducers;
export const employeePortalActions = {
  ...employeePortalSlice.actions,
  getCurrentEmployee,
  getCurrentEmployeeBanks,
  getCurrentEmployeeTimesheets,
  getCurrentEmployeePayslips,
  getCurrentEmployeeOthers,
  getCurrentEmployeeGuides,
  getCurrentEmployeeNotes,
};
