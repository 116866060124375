import api from '@/common/api';
import { IUser, UserRole } from '@/types/app';
import { useDebouncedSearch } from '@/utils/hooks';
import { AutoComplete } from 'antd';
import { useEffect } from 'react';

interface IProps {
  user?: IUser;
  onSelect: (id: string, record: IUser) => void;
  placeholder?: string;
  showSuggest?: boolean;
  disabled?: boolean;
  role?: UserRole;
  data?: string;
}

const SearchEndClients = ({ user, onSelect, showSuggest, placeholder = 'Search end client', disabled, role, data }: IProps) => {
  const { inputText, searchResults, setInputText } = useDebouncedSearch((text) =>
    api.getEndClients({
      keyword: text,
      limit: 10,
      ...(role ? { role } : null),
    }),
  );

  const _onSelect = (item: any, option: any) => {
    const record = searchResults?.result?.data?.data?.find((item: IUser) => item.id === option?.key);
    onSelect?.(option?.key, record);
  };

  useEffect(() => {
    if (showSuggest) {
      setInputText('');
    }
  }, []);

  useEffect(() => {
    setInputText(data || "");
  }, [data]);

  const renderOptions = () => {
    return searchResults?.result?.data?.data?.map((item: IUser) => ({
      value: item.name,
      label: <span>{item.name}</span>,
      key: item?.id,
    }));
  };
  return (
    <AutoComplete
      placeholder={placeholder}
      options={renderOptions()}
      onSelect={_onSelect}
      onSearch={setInputText}
      disabled={disabled}
      value={inputText}
      onChange={(e: string) => setInputText(e)}
    />
  );
};

export default SearchEndClients;
