import { ISmtp, IUser } from "@/types/app";
import { Button, Input, Popconfirm, Space, Spin, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { pageSizeOptions } from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "@/stores";
import { smtpsActions } from "@/stores/smtps";
import ModalSmtp from "./components/ModalSmtp";
import SmtpForm from "./components/SmtpForm";

const Smtps = () => {
  const { detail, loading, data } = useSelector((state) => state.smtps);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const ref = useRef<any>(null);


  useEffect(function () {
    dispatch(smtpsActions.getSmtps({ userID: userInfo?.id }));
  }, []);

  const onSubmitSmtp = (values: ISmtp) => {
    const newValues: ISmtp = { ...values, userID: userInfo?.id };
    console.log("*** test onSubmitSmtp newValues", newValues);

    if (newValues?.id && newValues?.id !== "") {
      dispatch(smtpsActions.updateSmtp(newValues)).then(() => {
        dispatch(smtpsActions.getSmtps({ userID: userInfo?.id }));
      });
    } else {
      dispatch(smtpsActions.createSmtp(newValues)).then(() => {
        dispatch(smtpsActions.getSmtps({ userID: userInfo?.id }));
      });
    }
  };

  return (
    <div className="container-fluid">
    <Spin
      tip="Loading"
      size="large"
      spinning={loading[smtpsActions.getSmtps.typePrefix]}
    >
      <div className="d-flex justify-content-between py-3">
          <div className="page-listing-title d-flex justify-content-between align-items-center mb-0">
            <span>SMTP Configuration</span>
          </div>
          <Button type="primary" className="d-flex align-items-center" onClick={() => ref.current.handleSubmit()}>
            Submit
          </Button>
        </div>
        <div className="bg-white rounded px-3 py-4">
          <div className="card-body">
            <SmtpForm data={data?.[0]} onSubmit={onSubmitSmtp} ref={ref} />
          </div>
        </div>
    </Spin>
      </div>
  );
};

export default Smtps;
