import { useEffect, useRef, useState, DragEvent, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from '@/types/app';
import { Button, Col, DatePicker, Form, Input, InputRef, Row, Select, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import { constantsToOptions } from '@/utils';
import { profileActions } from '@/stores/profile';
import { dateTimeFormat } from '@/common/constants/format';
import dayjs from 'dayjs';
import { ArrowLeftIcon } from '../common/IconComponents';
import { ReactComponent as IconUpload } from '@/assets/images/new-icons/icon-upload.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
const UserManagementForm = (props: { create?: boolean, page?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { constants, countries } = useSelector((state) => state.app);
  const { user, loading: profileLoading } = useSelector((state) => state.profile);
  const { detail, loading } = useSelector((state) => state.user);
  const { userInfo: userInfoStore } = useSelector((state) => state.auth);

  const role = Form.useWatch('role', form);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState('');
  const [attachmentUpload, setAttachmentUpload] = useState<File | null>(null);

  // ref
  const inputRef = useRef<InputRef>(null);


  useEffect(() => {
    if (id) {
      dispatch(userActions.getUser({ id }));
    } else if (!props.create) {
      if (user) {
        form.setFieldsValue(user);
        setFileName(user?.logo?.metadata?.name || '');
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (props?.page !== 'profile') {
      if (!props.create) {
        if (detail) {
          form.setFieldsValue(detail);
          setFileName(detail?.logo?.metadata?.name || '');
        }
      } else {
        form.resetFields();
        setAttachmentUpload(null);
        setFileName('');
      }
    }
  }, [detail]);

    // handle drag events
    const handleDrag = function (e: DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
  
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };
  
    // triggers when file is dropped
    const handleDrop = function (e: DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
  
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files);
      }
    };
  
    // triggers when file is selected with click
    const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
      e.preventDefault();
  
      if (e.target.files && e.target.files[0]) {
        handleFile(e.target.files);
        form.setFieldValue('fileName', e.target.files)
      }
    };
  
    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef?.current?.input?.click?.();
    };
  
    function handleFile(files: any) {
      setAttachmentUpload(files[0]);
      setFileName(files[0].name);
    }

  const transformBeforeSubmit = (e: IUser) => {
    return {
      ...e,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
      name: e.forename?.trim?.() + ' ' + e.surname?.trim?.(),
      id: detail?.id || userInfoStore?.id,
      attachmentUpload: attachmentUpload ? attachmentUpload : undefined,
      redirectURL: `${window?.location?.origin}/reset-password`,
      loginURL: `${window?.location?.origin}/login`,
    };
  };

  const handleSubmitForm = async (e: IUser) => {
    let result = transformBeforeSubmit(e);
    if (userInfoStore?.role === 'admin') {
      if (props.create) {
        dispatch(userActions.createUser(result))
          .unwrap()
          .then(() => {
            navigateBack();
          })
          .catch(console.log);
      } else dispatch(userActions.updateUser(result));
    } else {
      await dispatch(profileActions.updateMe(result));
      await dispatch(profileActions.getMe());
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-fluid">
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmitForm}
          form={form}
        >
          {userInfoStore?.role === 'admin' || props?.page === 'profile' ? (
            <>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
                  <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
                  <span>{props.create?"Create New User":"User Details"}</span>
                </div>
                {/* {!props.create ? (
                  <Button type="primary" role="button" className="d-flex align-items-center" onClick={() => setIsModalOpen(true)}>
                    Change Password
                  </Button>
                ) : null} */}
                <Space size="small">
                  <Button ghost type="primary" onClick={navigateBack}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading[userActions.createUser.typePrefix] ||
                      loading[userActions.updateUser.typePrefix] ||
                      profileLoading[profileActions.updateMe.typePrefix]||
                      false
              }>
                    {props.create ? 'Create' : 'Save'}
                  </Button>
                </Space>
              </div>
            </>
          ) : (
            <div className="d-flex p-4"></div>
          )}

          <div className="bg-white rounded px-3 py-4">
            <div className="card-body">
              {/* {props.create ? (
                      <div className="col-md-6">
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                            {
                              pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                              message:
                                "Password must contain at least one number and one uppercase and lowercase letter, and at least 6 characters",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter password"
                            autoComplete="new-password"
                            type="password"
                          />
                        </Form.Item>
                      </div>
                    ) : null} */}
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Forename"
                    name="forename"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'This field is required!',
                      },
                      {
                        pattern: /[a-zA-Z ]+$/,
                        message: 'Please enter on alphabets only.',
                      },
                    ]}
                  >
                    <Input placeholder="Enter forename" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Surname"
                    name="surname"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'This field is required!',
                      },
                      {
                        pattern: /[a-zA-Z ]+$/,
                        message: 'Please enter on alphabets only.',
                      },
                    ]}
                  >
                    <Input placeholder="Enter surname" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Username"
                    name="username"
                    normalize={(value) => value.trim()}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter username" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter email" type="email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Department"
                    name="department"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Select options={constantsToOptions(constants?.departments || [])} placeholder="Select department" />
                  </Form.Item>
                </Col>
              </Row>
              {userInfoStore?.role === 'admin' && props?.page === 'profile' ? null: (
              <Row>
                <Col span={12}>
                  <Form.Item label="Country" name="countryID">
                    <Select
                      placeholder="Select country"
                      filterOption={(input: string, option: any) => {
                        return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                      }}
                      showSearch
                    >
                      {countries?.map((item: any, index: number) => {
                        return (
                          <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                            <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>)}
              {props.create || userInfoStore?.role === 'admin' ? (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Role"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required!',
                        },
                      ]}
                    >
                      <Select
                        disabled={!props.create}
                        options={constantsToOptions(constants?.roles || [])}
                        placeholder="Select user role"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Form.Item noStyle name="role"></Form.Item>
              )}
              {role === 'client' && (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Prefix"
                      name="prefix"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter prefix" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {/* <Row>
                <Col span={12}>
                  <Form.Item name="country" noStyle></Form.Item>
                  <Form.Item
                    label="Country"
                    name="countryID"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select country"
                      filterOption={(input: string, option: any) => {
                        return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                      }}
                      showSearch
                    >
                      {countries.map((item: any, index: number) => {
                        return (
                          <Select.Option key={item?.id} value={item?.id} name={item?.name} data={item}>
                            <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}
              {!props.create ? (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Created At"
                      name="createdAt"
                      getValueProps={(i) => ({
                        value: i ? dayjs(i) : '',
                      })}
                    >
                      <DatePicker format={dateTimeFormat} className="w-100 p-12" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {role === 'client' &&
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Logo"
                    name="fileName"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    // rules={[{ required: true, message: 'This field is required!' }]}
                  >
                    <div id="form-file-upload" onDragEnter={handleDrag}>
                      <Input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} accept=".jpg,.jpeg,.png"/>
                      <label
                        style={{ backgroundColor: '#FBFAF8' }}
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={dragActive ? 'drag-active' : ''}
                      >
                        <div>
                          <IconUpload />
                          <p style={{ color: '#8A8988', fontSize: '14px', lineHeight: '20px', fontWeight: 500 }}>
                            Drag and drop your file here or
                          </p>
                          <Button className="upload-button" onClick={onButtonClick}>
                            Click here
                          </Button>
                        </div>
                      </label>
                      {dragActive && (
                        <div
                          id="drag-file-element"
                          onDrop={handleDrop}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                        ></div>
                      )}
                    </div>
                    {fileName && (
                      <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '16px' }}>
                        <div className="d-flex align-items-center" style={{ gap: '6px' }}>
                          <IconAttachment />
                          <p style={{ margin: 0, fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: '#666564' }}>{fileName}</p>
                        </div>
                        <IconChecked />
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>}
            </div>
          </div>
        </Form>
      </div>
      <ChangePasswordModal isOpen={isModalOpen} setIsOpen={(e: boolean) => setIsModalOpen(e)} />
    </>
  );
};

export default UserManagementForm;
