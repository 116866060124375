import { IAuthoriseExpensesListTable } from '@/types/app';
import { Button, Form, Grid, Input, Space, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FileTwoTone, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import RejectAuthoriseExpensesModal from '@/components/Modals/RejectAuthoriseExpensesModal';
import { AuthoriseExpensesStatus, enumOptions } from '@/common/constants/enums';
import { currencyFormat, pageSizeOptions, taxFormat } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { ROUTE_PATH } from '@/common/constants/routes';
import { Link } from 'react-router-dom';
import { getBreakPoint } from '@/utils';

const { useBreakpoint } = Grid;

const AuthoriseExpenses = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [isRejectExpenseModalOpen, setIsRejectExpenseModalOpen] = useState<boolean>(false);

  const fakeData: IAuthoriseExpensesListTable[] = [
    {
      id: '1',
      reference: 'AG-001',
      forename: 'Marshall',
      surname: 'Nichols',
      dateOfExpense: '18/08/2023',
      expenseType: 'mileage',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'rejected',
      updatedDate: '18/08/2023',
      rejectReason: 'abcd',
    },
    {
      id: '2',
      reference: 'AG-002',
      forename: 'Susie',
      surname: 'Willis',
      dateOfExpense: '18/08/2023',
      expenseType: 'travel',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/866141ce-67dc-4ae0-9bde-bf4d1f455084-signed_zoho.pdf',
      status: 'awaitingApproval',
      updatedDate: '18/08/2023',
    },
    {
      id: '3',
      reference: 'AG-003',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '4',
      reference: 'AG-004',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '5',
      reference: 'AG-005',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '6',
      reference: 'AG-006',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '7',
      reference: 'AG-007',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '8',
      reference: 'AG-008',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '9',
      reference: 'AG-009',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '10',
      reference: 'AG-0010',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
    {
      id: '11',
      reference: 'AG-0011',
      forename: 'Debra',
      surname: 'Stewart',
      dateOfExpense: '18/08/2023',
      expenseType: 'subsistence',
      currency: 'VND',
      amount: 100,
      tax: 10,
      filePath:
        'https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf',
      status: 'approved',
      updatedDate: '18/08/2023',
    },
  ];

  const columns: ColumnsType<IAuthoriseExpensesListTable> = [
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      fixed: isMobile ? false : 'left',
      render: (_, record) => (
        <Space size={0}>
          <Tooltip title="View Receipt">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(_.filePath);
                console.log('open receipt');
              }}
            >
              <FileTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="Approve">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                console.log('approved');
              }}
            >
              <CheckCircleTwoTone twoToneColor="#4EBA21" />
            </Button>
          </Tooltip>
          <Tooltip title="Reject">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                setSelectedId(_.id);
                setIsRejectExpenseModalOpen(true);
              }}
            >
              <CloseCircleTwoTone twoToneColor="red" />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      align: 'center',
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => (
        <span>
          {record.forename} {record.surname}
        </span>
      ),
    },
    // {
    //     title: 'Client Name',
    //     dataIndex: 'clientName',
    //     key: 'clientName',
    //     width: 120,
    //     align: 'center',
    // },
    {
      title: 'Date Of Expense',
      dataIndex: 'dateOfExpense',
      key: 'dateOfExpense',
      width: 140,
      align: 'center',
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      key: 'expenseType',
      width: 120,
      align: 'center',
      render: (value) => {
        const result = enumOptions.ExpenseType.find((item) => item.value === value);
        // return (<b style={{color: result.color}}>{result.label}</b>)
        return <Tag color="processing">{result?.label}</Tag>;
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (value) => currencyFormat(value),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      align: 'center',
      render: (value) => taxFormat(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      align: 'center',
      render: (value) => {
        const result = enumOptions.AuthoriseExpensesStatus.find((item) => item.value === value);
        // return (<b style={{color: result.color}}>{result.label}</b>)
        if (result)
          return (
            <Tag
              color={
                result.label === AuthoriseExpensesStatus.rejected
                  ? 'red'
                  : result.label === AuthoriseExpensesStatus.approved
                    ? 'green'
                    : 'gold'
              }
            >
              {result.label}
            </Tag>
          );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: IAuthoriseExpensesListTable[]) => {
    console.log(newSelectedRowKeys, selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement> | string) => {};

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end p-2">
        <Link to={ROUTE_PATH.AUTHORISE_EXPENSES_CREATE}>
          <Button type="primary">Add New</Button>
        </Link>
      </div>
      <div className="bg-white shadow-lg rounded" id="authorise-expensive">
        <div className="card-header">
          <h3 className="card-title">Authorise Expenses List</h3>
          <div className="card-options">
            <Form onFinish={(e) => handleSearchSubmit(e)}>
              <div className="input-group">
                <Input.Search placeholder="Input search text" onSearch={(e) => handleSearchSubmit(e)} allowClear className="w-100" />
              </div>
            </Form>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={fakeData}
              size="small"
              rowSelection={rowSelection}
              rowKey={(record: IAuthoriseExpensesListTable) => record.id || 'random'}
              scroll={{ x: 1600, y: 480 }}
              pagination={{
                showSizeChanger: true,
                defaultCurrent: 1,
                total: fakeData.length,
                position: ['bottomLeft'],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
            />
          </div>
        </div>
      </div>
      <RejectAuthoriseExpensesModal
        expenseId={selectedId}
        isOpen={isRejectExpenseModalOpen}
        setIsOpen={(val: boolean) => setIsRejectExpenseModalOpen(val)}
      />
    </div>
  );
};

export default AuthoriseExpenses;
