import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEmployee, IEmployeeNote, IEmployeeNoteResponse, IEmployeeResponse } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { IEmployeeState } from './employee';
import { uploadS3 } from '@/common/api/s3';
import { EndpointsParams } from '@/common/api/endpoints';

export const initialState: IEmployeeState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},

  timesheetDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  guideDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  othersDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  payslipDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  banks: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
};

const getOffboardings = createAsyncThunk(
  `${SLICE_NAME.OFFBOARDING}/getOffboardings`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getOffboardings<IEmployeeResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getOffboarding = createAsyncThunk(`${SLICE_NAME.OFFBOARDING}/getOffboarding`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getOffboarding<{ data: IEmployee }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateOffboarding = createAsyncThunk(`${SLICE_NAME.OFFBOARDING}/updateOffboarding`, async (form: any, { rejectWithValue }) => {
  try {
    const data = await api.updateOffboarding<{ data: IEmployee }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateOffboardingStatus = createAsyncThunk(
  `${SLICE_NAME.OFFBOARDING}/updateOffboardingStatus`,
  async (form: { offBoardingStatus: string } & EndpointsParams['updateOffboardingStatus'], { rejectWithValue }) => {
    try {
      const data = await api.updateOffboardingStatus<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteOffboarding = createAsyncThunk(`${SLICE_NAME.OFFBOARDING}/deleteOffboarding`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteOffboarding<{ data: IEmployee }>(query);

    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getOffboardingNotes = createAsyncThunk(
  `${SLICE_NAME.OFFBOARDING}/getOffboardingNotes`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getOffboardingNotes<IEmployeeNoteResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createOffboardingNote = createAsyncThunk(
  `${SLICE_NAME.OFFBOARDING}/createOffboardingNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'offboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createOffboardingNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateOffboardingNote = createAsyncThunk(
  `${SLICE_NAME.OFFBOARDING}/updateOffboardingNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'offboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.updateOffboardingNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IEmployee) => {
  return {
    ...response,
    // dob: response.dob ? response.dob * 1000 : 0,
    startDate: response.startDate ? response.startDate * 1000 : 0,
    endDate: response.endDate ? response.endDate * 1000 : 0,
    anticipatedEndDate: response.anticipatedEndDate ? response.anticipatedEndDate * 1000 : 0,
  };
};

const offboardingSlice = createSlice({
  name: SLICE_NAME.OFFBOARDING,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffboardings.fulfilled, (state, { payload }) => {
      state.loading[getOffboardings.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getOffboardings.pending, (state, { payload }) => {
      state.loading[getOffboardings.typePrefix] = true;
    });
    builder.addCase(getOffboardings.rejected, (state, { payload }) => {
      state.loading[getOffboardings.typePrefix] = false;
    });
    builder.addCase(getOffboarding.fulfilled, (state, { payload }) => {
      state.loading[getOffboarding.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getOffboarding.pending, (state, { payload }) => {
      state.loading[getOffboarding.typePrefix] = true;
    });
    builder.addCase(getOffboarding.rejected, (state, { payload }) => {
      state.loading[getOffboarding.typePrefix] = false;
    });
    builder.addCase(updateOffboarding.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.OFFBOARDING);
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateOffboarding.pending, (state, { payload }) => {
      state.loading[updateOffboarding.typePrefix] = true;
    });
    builder.addCase(updateOffboarding.rejected, (state, { payload }) => {
      state.loading[updateOffboarding.typePrefix] = false;
    });
    builder.addCase(updateOffboardingStatus.fulfilled, (state, { payload }) => {
      state.detail = mapFromResponse(payload.data.data);
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateOffboardingStatus.pending, (state, { payload }) => {
      state.loading[updateOffboardingStatus.typePrefix] = true;
    });
    builder.addCase(updateOffboardingStatus.rejected, (state, { payload }) => {
      state.loading[updateOffboardingStatus.typePrefix] = false;
    });
    builder.addCase(deleteOffboarding.fulfilled, (state, { payload }) => {
      openNotification('success', `${payload.data.data?.name || (payload.data.data?.forename+ " " + payload.data.data?.surname)} deleted successfully!`);
      state.loading[deleteOffboarding.typePrefix] = false;
    });
    builder.addCase(deleteOffboarding.pending, (state, { payload }) => {
      state.loading[deleteOffboarding.typePrefix] = true;
    });
    builder.addCase(deleteOffboarding.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteOffboarding.typePrefix] = false;
    });
    builder.addCase(getOffboardingNotes.fulfilled, (state, { payload }) => {
      state.loading[getOffboardingNotes.typePrefix] = false;
      state.notes = payload.data.data;
      state.notePagination = payload.data.pagination;
    });
    builder.addCase(getOffboardingNotes.pending, (state, { payload }) => {
      state.loading[getOffboardingNotes.typePrefix] = true;
    });
    builder.addCase(getOffboardingNotes.rejected, (state, { payload }) => {
      state.loading[getOffboardingNotes.typePrefix] = false;
    });
    builder.addCase(createOffboardingNote.fulfilled, (state, { payload }) => {
      state.loading[createOffboardingNote.typePrefix] = false;
      openNotification('success', 'Note created successfully!');
    });
    builder.addCase(createOffboardingNote.pending, (state, { payload }) => {
      state.loading[createOffboardingNote.typePrefix] = true;
    });
    builder.addCase(createOffboardingNote.rejected, (state, { payload }) => {
      state.loading[createOffboardingNote.typePrefix] = false;
      openNotification('error', 'Creation note failed!');
    });
    builder.addCase(updateOffboardingNote.fulfilled, (state, { payload }) => {
      openNotification('success', 'Update successfully!');
      state.loading[updateOffboardingNote.typePrefix] = false;
    });
    builder.addCase(updateOffboardingNote.pending, (state, { payload }) => {
      state.loading[updateOffboardingNote.typePrefix] = true;
    });
    builder.addCase(updateOffboardingNote.rejected, (state, { payload }) => {
      state.loading[updateOffboardingNote.typePrefix] = false;
    });
  },
});

export const offboardingReducer = offboardingSlice.reducer;
export const offboardingCaseReducers = offboardingSlice.caseReducers;
export const offboardingActions = {
  ...offboardingSlice.actions,
  getOffboardings,
  getOffboarding,
  updateOffboarding,
  updateOffboardingStatus,
  deleteOffboarding,
  getOffboardingNotes,
  createOffboardingNote,
  updateOffboardingNote,
};
