import { IDashboardCountry, IUser } from "@/types/app";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Link, useLocation } from "react-router-dom";
import { EyeTwoTone, DeleteTwoTone, LoginOutlined, PlusOutlined } from "@ant-design/icons";
import { ROUTE_PATH } from "@/common/constants/routes";
import dayjs from "dayjs";
import {
  dateFormat,
  dateTimeFormat,
  pageSizeOptions,
} from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "@/stores";
import { userActions } from "@/stores/user";
import { authActions } from "@/stores/auth";
import { UserRole } from "@/common/constants/enums";
import { matchValueToLabel } from "@/utils";
import { isRootAdmin } from "@/utils/auth";
import { DeleteIcon, EyeIcon, LoginAsIcon, SearchIcon } from "@/components/common/IconComponents";
import { dashboardActions } from "@/stores/dashboard";
import { SorterResult, TablePaginationConfig } from "antd/es/table/interface";
const DashboardAllCountries = () => {
  const { constants } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { dashboardAllCountries, loading } = useSelector((state) => state.dashboard);

  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const totalEmployeesFrom = search?.get('totalEmployeesFrom') || undefined;

  const columns: ColumnsType<IDashboardCountry> = [
    {
      title: 'Country',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: true,
      render: (name: any, elm: IDashboardCountry) => (
        <Space>
          <img
            src={elm?.flag}
            width="16"
            height="12"
            alt={`${elm?.name}`}
            className="mr-2"
          />
          {elm?.name}
        </Space>
      ),
    },
    {
      title: 'No. Clients',
      dataIndex: 'totalClients',
      key: 'totalClients',
      width: 200,
      sorter: true,
      render: (totalClients: number, elm: IDashboardCountry) => (
        <Link to={`${userInfo?.role === UserRole.company?ROUTE_PATH.DASHBOARD_ALL_CLIENTS:ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS}?countryID=${elm?.id}`}>
          {userInfo?.role === UserRole.company?totalClients:elm?.totalEndClients }
        </Link>
      ),
    },
    {
      title: 'No. Employees',
      dataIndex: 'totalEmployees',
      key: 'totalEmployees',
      width: 200,
      sorter: true,
      render: (totalClients: number, elm: IDashboardCountry) => (
        <Link to={`${ROUTE_PATH.EMPLOYEE_LIST}?countryOfHireID=${elm?.id}`}>
          {totalClients}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      render: (value) => {
        return (
          <Tag color={value === 'active' ? 'success' :  'default'} className="text-uppercase">
            {value}
          </Tag>
        );
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   width: 180,
    //   fixed: 'right',
    //   render: (_, record) => (
    //     <div>
    //         <Button type="text" onClick={() => dispatch(authActions.loginAsUser(record.id))}>
    //           <LoginAsIcon />
    //         </Button>
    //       <Link to={_.id}>
    //         <Tooltip title="View Detail">
    //           <Button type="text">
    //             <EyeIcon />
    //           </Button>
    //         </Tooltip>
    //       </Link>
    //       <Popconfirm
    //         title="Delete this employee"
    //         description="Are you sure to delete?"
    //         onConfirm={() => {
    //           dispatch(
    //             userActions.deleteUser({
    //               id: record.id,
    //             }),
    //           )
    //             ?.unwrap()
    //             ?.then(() => {
    //               dispatch(userActions.getUsers({}));
    //             });
    //         }}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Tooltip title="Delete">
    //           <Button type="text">
    //             <DeleteIcon />
    //           </Button>
    //         </Tooltip>
    //       </Popconfirm>
    //     </div>
    //   ),
    // },
  ];

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(dashboardActions.getDashboardAllCountries({ keyword, totalEmployeesFrom }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(dashboardActions.getDashboardAllCountries({ page, limit: pageSize, totalEmployeesFrom }));
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDashboardCountry> | SorterResult<IDashboardCountry>[],
  ) => {
      dispatch(
        dashboardActions.getDashboardAllCountries({
          page: pagination.current,
          limit: pagination.pageSize,
          keyword: searchText,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
          totalEmployeesFrom,
        }),
      );
  };

  useEffect(
    function () {
      if (userInfo?.role === UserRole.admin) dispatch(userActions.getUsers({}));
    },
    [userInfo]
  );

  useEffect(() => {
    if (userInfo?.role !== UserRole.admin) {
      if (location.search && (totalEmployeesFrom) ) {
        dispatch(dashboardActions.getDashboardAllCountries({totalEmployeesFrom}));
      } else dispatch(dashboardActions.getDashboardAllCountries({}));
    }
  }, [location.search]);

  return (
    <div className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[dashboardActions.getDashboardAllCountries.typePrefix]}>
        <div className="d-flex justify-content-between py-3">
          <div className="page-listing-title d-flex justify-content-between align-items-center mb-0">
            <span>Countries</span>
          </div>
        </div>
        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={dashboardAllCountries?.data}
                size="small"
                scroll={{ x: 1000, y: 600 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handleSortAndPaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: dashboardAllCountries?.pagination?.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardAllCountries;
