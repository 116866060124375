// import NewHireCreateFormStep1 from "@/components/Forms/NewHire/NewHireCreateFormStep1";
// import NewHireCreateFormStep2 from "@/components/Forms/NewHire/NewHireCreateFormStep2";
// import NewHireCreateFormStep3 from "@/components/Forms/NewHire/NewHireCreateFormStep3";
// import NewHireCreateFormStep4 from "@/components/Forms/NewHire/NewHireCreateFormStep4";
// import NewHireCreateFormStep5 from "@/components/Forms/NewHire/NewHireCreateFormStep5";
// import { IEmployee } from "@/types/app";
// import { Button, Steps } from "antd";
// import dayjs from "dayjs";
// import { useCallback, useState } from "react";
// import { useDispatch, useSelector } from "@/stores";
// import { newHireActions } from "@/stores/newHire";
// import { useNavigate } from "react-router-dom";
// import { LeftOutlined } from "@ant-design/icons";

// const NewHireCreate = () => {
//   const [step, setStep] = useState(0);
//   const [stepComplete, setStepComplete] = useState(0);
//   const { userInfo } = useSelector((state) => state.auth);
//   console.log("stepComplete", stepComplete);

//   const initialState: IEmployee = {
//     title: "mr",
//     forename: "",
//     surname: "",
//     email: "",
//     employeeNationality: "VN",
//     countryOfHire: "VN",
//     needVisa: true,
//     jobTitle: "",
//     jobDescription: "",
//     currency: "EUR",
//     payFrequency: "hourly",
//     contractType: "ongoing",
//     requireHealthInsurance: true,
//     status: "in_review",
//     registrationStatus: "in_review",
//     maritalStatus: "single",
//     workType: "full_time",
//   };
//   const [requestData, setRequestData] = useState<IEmployee>(initialState);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const navigateBack = () => {
//     navigate(-1);
//   };

//   const handleNextStep = useCallback(
//     (data: any) => {
//       setRequestData({ ...requestData, ...data });
//       if (step < 3) setStep(step + 1);
//       setStepComplete(step + 1);
//       console.log("next", data);
//     },
//     [step]
//   );

//   const handlePrevStep = useCallback(
//     (data: any) => {
//       setRequestData({ ...requestData, ...data });
//       setStep(step - 1);
//     },
//     [step]
//   );

//   const handleStepChange = (step: number) => {
//     setStep(step);
//   };

//   const transformBeforeSubmit = (e: IEmployee) => {
//     console.log("befor submit", e);

//     return {
//       ...e,
//       role: userInfo?.role,
//       startDate: e.startDate ? dayjs(e.startDate).unix() : 0,
//       endDate: e.endDate ? dayjs(e.endDate).unix() : 0,
//       dob: e.dob ? dayjs(e.dob).unix() : 0,
//     };
//   };

//   const handleSubmitRequest = (e: any) => {
//     const result = transformBeforeSubmit({ ...requestData, ...e });
//     console.log("submit", result);
//     dispatch(newHireActions.createNewHire(result));
//   };

//   return (
//     <div className="container-fluid mt-4">
//       <Button
//         type="primary"
//         onClick={navigateBack}
//         className=" mb-3 d-flex align-items-center"
//       >
//         <LeftOutlined />
//         <span>Back</span>
//       </Button>
//       <Steps
//         type="navigation"
//         current={step}
//         onChange={handleStepChange}
//         items={[
//           {
//             title: "Personal Info",
//             disabled: stepComplete < 0,
//           },
//           // {
//           //   title: "Company Info",
//           //   disabled: stepComplete < 1,
//           // },
//           {
//             title: "Position Details",
//             disabled: stepComplete < 1,
//           },
//           {
//             title: "Payment",
//             disabled: stepComplete < 2,
//           },
//           {
//             title: "Additional Info",
//             disabled: stepComplete < 3,
//           },
//         ]}
//       />
//       <div className="request-new-hire-form d-flex justify-content-center">
//         {step === 0 ? (
//           <NewHireCreateFormStep1
//             initialState={requestData}
//             handleNextStep={handleNextStep}
//           />
//         ) : // : step === 1 ? (
//         //   <NewHireCreateFormStep2
//         //     initialState={requestData}
//         //     handlePrevStep={handlePrevStep}
//         //     handleNextStep={handleNextStep}
//         //   />
//         // )
//         step === 1 ? (
//           <NewHireCreateFormStep3
//             initialState={requestData}
//             handlePrevStep={handlePrevStep}
//             handleNextStep={handleNextStep}
//           />
//         ) : step === 2 ? (
//           <NewHireCreateFormStep4
//             initialState={requestData}
//             handlePrevStep={handlePrevStep}
//             handleNextStep={handleNextStep}
//           />
//         ) : step === 3 ? (
//           <NewHireCreateFormStep5
//             initialState={requestData}
//             handlePrevStep={handlePrevStep}
//             handleNextStep={handleNextStep}
//             handleSubmitRequest={handleSubmitRequest}
//           />
//         ) : null}
//       </div>
//       {/* <NewHireCreateForm step={step} onSetStep={(e: number) => setStep(e)} /> */}
//     </div>
//   );
// };

// export default NewHireCreate;

import NewHireForm from "@/components/Forms/NewHire/NewHireForm";

const NewHireDetail = (): JSX.Element => {
  return <NewHireForm create={true} />;
};

export default NewHireDetail;
