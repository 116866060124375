import { dateFormat } from '@/common/constants/format';
import { DeleteIcon, EyeIcon } from '@/components/common/IconComponents';
import { IGroupPermission, IUser } from '@/types/app';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';

export const getColumns = ({
  onDelete,
  onEdit,
  isMobile,
}: {
  onEdit?: (item: IGroupPermission) => void;
  onDelete?: (item: IGroupPermission) => void;
  isMobile: boolean;
}): ColumnsType<IGroupPermission> => [
  {
    title: 'Actions',
    key: 'action',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (_, record) => (
      <Space size="middle">
        <Tooltip title="Open View">
          <Button type="text" size="small" onClick={() => onEdit?.(record)}>
            <EyeIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Popconfirm
            title="Delete group permission"
            description="Are you sure you want to proceed?"
            onConfirm={() => onDelete?.(record)}
            okText="Yes"
            cancelText="No"
            className="d-flex align-items-center"
            arrow={false}
          >
            <Button type="text" size="small">
              <DeleteIcon />
            </Button>
          </Popconfirm>
        </Tooltip>
      </Space>
    ),
  },
  {
    title: 'Group',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    sorter: true,
    render: (value, record) => {
      return (
        <>
          {value ? (
            <Paragraph
              ellipsis={{
                rows: 1,
                // expandable: false,
                // tooltip: value,
              }}
              title={value}
            >
              {value}
            </Paragraph>
          ) : null}
        </>
      );
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 500,
    sorter: true,
    render: (value) =>
      value ? (
        <Paragraph
          ellipsis={{
            rows: 1,
            // expandable: false,
            // tooltip: value,
          }}
          title={value}
        >
          {value}
        </Paragraph>
      ) : null,
  },
  {
    title: 'Updated By',
    dataIndex: 'updatedByUser',
    key: 'updatedByUser',
    width: 200,
    sorter: true,
    render: (user: IUser, record) => user?.name ?? 'N/A',
  },
  {
    title: 'Last Update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 200,
    sorter: true,
    render: (updatedAt, record) => dayjs.unix(updatedAt ?? record.createdAt).format(dateFormat),
  },
];
