import { ChangeEvent, useEffect, useState, DragEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from '@/types/app';
import { Button, Col, DatePicker, Form, Input, InputRef, Row, Select, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import { constantsToOptions } from '@/utils';
import { profileActions } from '@/stores/profile';
import { dateTimeFormat } from '@/common/constants/format';
import dayjs from 'dayjs';
import { ArrowLeftIcon } from '../common/IconComponents';
import { ReactComponent as IconUpload } from '@/assets/images/new-icons/icon-upload.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
import { endClientsActions } from '@/stores/endClients';
import { companyUsersActions } from '@/stores/companyUsers';
const CreateClientForm = (props: { create?: boolean, isEndClient?: boolean }) => {
  const {create, isEndClient } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { constants, countries } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.profile);
  const { detail, loading } = useSelector((state) => state.endClients);
  const { detail: companyUserDetail, loading: companyUserLoading } = useSelector((state) => state.companyUsers);

  const { userInfo: userInfoStore } = useSelector((state) => state.auth);

  const role = Form.useWatch('role', form);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState('');
  const [attachmentUpload, setAttachmentUpload] = useState<File | null>(null);
  // ref
  const inputRef = useRef<InputRef>(null);

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
      form.setFieldValue('fileName', e.target.files)
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.input?.click?.();
  };

  function handleFile(files: any) {
    setAttachmentUpload(files[0]);
    setFileName(files[0].name);
  }

  useEffect(() => {
    if (id) {
      if(isEndClient)
        dispatch(endClientsActions.getEndClient({ id }));
      else dispatch(companyUsersActions.getCompanyUser({ id }));
    } else if (!create) {
      if (user) {
        form.setFieldsValue(user);
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (!create) {
      if (detail) {
        form.setFieldsValue(detail);
      }
    } else {
      form.resetFields();
      setAttachmentUpload(null);
      setFileName('');
    }
  }, [detail]);

  useEffect(() => {
    if (!create) {
      if (companyUserDetail) {
        form.setFieldsValue(companyUserDetail);
        setFileName(companyUserDetail?.logo?.metadata?.name || '');
      }
    } else {
      form.resetFields();
      setAttachmentUpload(null);
      setFileName('');
    }
  }, [companyUserDetail]);

  const transformBeforeSubmit = (e: IUser) => {
    return {
      ...e,
      // forename: e.forename?.trim?.(),
      // surname: e.surname?.trim?.(),
      name: e?.name?.trim?.(),
      id: id,
      attachmentUpload: attachmentUpload ? attachmentUpload : undefined,
      redirectURL: `${window?.location?.origin}/reset-password`,
      loginURL: `${window?.location?.origin}/login`,
      countryID: e?.countryID || user?.countryID,
      email: create?e?.email:undefined,
    };
  };

  const handleSubmitForm = (e: IUser) => {
    let result = transformBeforeSubmit(e);
    if(isEndClient){
      if (create) {
        dispatch(endClientsActions.createEndClient(result))
          .unwrap()
          .then(() => {
            navigateBack();
          })
          .catch(console.log);
      } else dispatch(endClientsActions.updateEndClient(result));
    } else {
        if (create) {
          dispatch(companyUsersActions.createCompanyUser(result))
            .unwrap()
            .then(() => {
              navigateBack();
            })
            .catch(console.log);
        } else dispatch(companyUsersActions.updateCompanyUser(result));
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-fluid">
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleSubmitForm}
          form={form}
        >
          <div className="d-flex justify-content-between align-items-center py-3">
            <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
              <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
              <span>{create ? "Create New Client": "Update Client"}</span>
            </div>
            <Space size="small">
              <Button ghost type="primary" onClick={navigateBack}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit"
              loading={loading[endClientsActions.createEndClient.typePrefix] ||
                      loading[endClientsActions.updateEndClient.typePrefix] ||
                      companyUserLoading[companyUsersActions.createCompanyUser.typePrefix] ||
                      companyUserLoading[companyUsersActions.updateCompanyUser.typePrefix] ||
                      false
              }
              >
                {create ? 'Create' : 'Save'}
              </Button>
            </Space>
          </div>
          <div className="bg-white rounded px-3 py-4">
            <div className="card-body">
              <Row gutter={[16,16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Client Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter username" autoComplete="new-password" />
                  </Form.Item>
                </Col>
                {isEndClient? 
                  <Col xs={24} sm={12}>
                    <Form.Item label="Country" name="countryID">
                      <Select
                        placeholder="Select country"
                        filterOption={(input: string, option: any) => {
                          return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                        }}
                        showSearch
                      >
                        {countries?.map((item: any, index: number) => {
                          return (
                            <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                              <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                              {item?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>:
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Client Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter email" type="email" />
                  </Form.Item>
                </Col>}
              </Row>
              <Row gutter={[16,16]}>
                {isEndClient? null :
                  <Col span={24}>
                    <Form.Item
                      label="Logo"
                      name="fileName"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // rules={[{ required: true, message: 'This field is required!' }]}
                    >
                      <div id="form-file-upload" onDragEnter={handleDrag}>
                        <Input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} accept=".jpg,.jpeg,.png"/>
                        <label
                          style={{ backgroundColor: '#FBFAF8' }}
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                          className={dragActive ? 'drag-active' : ''}
                        >
                          <div>
                            <IconUpload />
                            <p style={{ color: '#8A8988', fontSize: '14px', lineHeight: '20px', fontWeight: 500 }}>
                              Drag and drop your file here or
                            </p>
                            <Button className="upload-button" onClick={onButtonClick}>
                              Click here
                            </Button>
                          </div>
                        </label>
                        {dragActive && (
                          <div
                            id="drag-file-element"
                            onDrop={handleDrop}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                          ></div>
                        )}
                      </div>
                      {fileName && (
                        <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '16px' }}>
                          <div className="d-flex align-items-center" style={{ gap: '6px' }}>
                            <IconAttachment />
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: '#666564' }}>{fileName}</p>
                          </div>
                          <IconChecked />
                        </div>
                      )}
                    </Form.Item>
                  </Col>}
              </Row>
            </div>
          </div>
        </Form>
      </div>
      <ChangePasswordModal isOpen={isModalOpen} setIsOpen={(e: boolean) => setIsModalOpen(e)} />
    </>
  );
};

export default CreateClientForm;
