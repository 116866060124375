import { useDispatch, useSelector } from '@/stores';
import { activityLogActions } from '@/stores/activityLogs';
import { Modal, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@/components/common/IconComponents';
import { ROUTE_PATH } from '@/common/constants/routes';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';
import { dateFormat, dateTimeFormat } from '@/common/constants/format';
import { IActivityLog, IConstants, IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { IconArrowRight } from '@/components/Icons';
import _ from 'lodash';

function isValidUnixTimestamp(input: string): boolean {
  const unixTimestampRegex = /^[0-9]{10,13}$/;

  if (!unixTimestampRegex.test(input)) {
    return false;
  }

  const timestamp = parseInt(input, 10);
  const date = new Date(input.length === 10 ? timestamp * 1000 : timestamp);

  // Check if the timestamp is within a reasonable range (e.g., between 1970 and 2100)
  return date.getTime() > 0 && date.getFullYear() >= 1970 && date.getFullYear() <= 2100;
}

const constantFieldMapper: Record<string, keyof IConstants> = {
  registrationStatus: 'registrationStatuses',
  offBoardingStatus: 'offBoardingStatuses',
  status: 'statuses',
  title: 'employeeTitles',
  workType: 'workTypes',
  payrollType: 'payrollTypes',
  contractType: 'contactTypes',
  payFrequency: 'payFrequencies',
  maritalStatus: 'maritalStatuses',
};

const ActivityLogDetailsModal = (props: any) => {
  const {isModalOpen, handleCloseModal} = props;
  const dispatch = useDispatch();
  const { detail, loading } = useSelector((state) => state.activityLog);
  const { constants } = useSelector((state) => state.app);

  const groupedLogs = groupBy(detail, 'createdAt');
  const timestamps = Object.keys(groupedLogs);

  const { id } = useParams();
  const navigate = useNavigate();

  function getFieldValue(fieldValue: any, fieldKey?: string) {
    if (typeof fieldValue === 'string' || typeof fieldValue === 'number' || typeof fieldValue === 'boolean') {
      if (typeof fieldValue === 'number' && isValidUnixTimestamp(`${fieldValue}`)) {
        return dayjs.unix(fieldValue).format(dateFormat);
      }
      if (typeof fieldValue === 'string' && fieldKey==='dob' ) {
        return dayjs(fieldValue).format(dateFormat);
      }

      if (fieldKey && constantFieldMapper[fieldKey]) {
        if (!constants) return `${fieldValue}`;

        return matchValueToLabel(constants[constantFieldMapper[`${fieldKey}`]], `${fieldValue}`);
      }

      return `${fieldValue}`;
    } else if (fieldValue instanceof Date) {
      return fieldValue.toISOString(); // Returns date as an ISO string
    } else if (typeof fieldValue === 'object' && fieldValue !== null) {
      if ('forename' in fieldValue) {
        return `${fieldValue.forename} ${fieldValue.surname}`;
      }

      return fieldValue.name || fieldValue.id || `${fieldValue}`;
    }

    return null; // Handles undefined, null, or any unexpected types
  }

  useEffect(() => {
    if (id) {
      dispatch(activityLogActions.getActivityLog({ employeeID: id }));
    }
  }, [id]);

  const navigateBack = () => {
    navigate(ROUTE_PATH.EMPLOYEE_ACTIVITY_LOGS || '-1');
  };

  const renderItemTitle = (item: IActivityLog) => {
    switch (item.type) {
      case 'created_document_guides':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            document guides
          </>
        );
      case 'created_document_others':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            other documents
          </>
        );
      case 'created_document_time_sheet':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            timesheet
          </>
        );
      case 'created_document_invoice':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            invoice
          </>
        );
      case 'created_document_payslips':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            payslips
          </>
        );
      case 'created_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}`}</Link>
          </>
        );
      case 'deleted_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username}{' '}
            <span className="activity-type">{matchValueToLabel(constants?.activityLogTypes || [], item.type)}</span>
          </>
        );

      case 'created_note_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">added</span> new note
          </>
        );
      case 'off_boarding_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">updated status</span> to{' '}
            <span className="activity-type">Offboarding In Review</span>
          </>
        );
      case 'on_boarding_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">updated status</span> to{' '}
            <span className="activity-type">Onboarding In Review</span>
          </>
        );
      case 'request_terminated_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">request terminated</span>
          </>
        );
      case 'sent_employee_to_company':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">sent profile</span> for Onboarding review
          </>
        );
      case 'updated_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} <span className="activity-type">edited</span> general info
          </>
        );
      case 'updated_onboarding_status_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} updated employee <span className="activity-type">onboarding status</span>
          </>
        );
      case 'updated_off_boarding_status_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} updated employee <span className="activity-type">offboarding status </span>
          </>
        );
      case 'updated_bank_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} updated{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            bank
          </>
        );
      case 'created_bank_employee':
        return (
          <>
            {item.updatedByUser?.name || item.updatedByUser?.username} created{' '}
            <Link
              className="activity-type"
              to={`${ROUTE_PATH.EMPLOYEE_LIST}/${item.employeeSnap?.id ?? item.employeeID}`}
            >{`${item.employeeSnap?.name ?? ''}'s `}</Link>{' '}
            bank
          </>
        );

      default:
        return null;
    }
  };

  const renderItemContent = (item: IActivityLog) => {
    switch (item.type) {
      case 'created_document_guides':
      case 'created_document_others':
      case 'created_document_time_sheet':
      case 'created_document_invoice':
      case 'created_document_payslips':
      case 'created_employee':
      case 'created_note_employee':
      case 'deleted_employee':
      case 'off_boarding_employee':
      case 'on_boarding_employee':
      case 'sent_employee_to_company':
      case 'created_bank_employee':
        return null;
      case 'updated_employee':
      case 'updated_bank_employee':
        return (
          <div
            className="mt-3 rounded-lg border-[1px] border-neutral-100 bg-neutral-50 p-4"
            style={{ marginTop: 3, borderRadius: 8, border: '1px solid #E4E3E2', background: '#FBFAF8', padding: 12 }}
          >
            {renderDataChangeRows(item)}
          </div>
        );
      case 'updated_onboarding_status_employee':
        return (
          <div
            className="mt-3 rounded-lg border-[1px] border-neutral-100 bg-neutral-50 p-4"
            style={{ marginTop: 3, borderRadius: 8, border: '1px solid #E4E3E2', background: '#FBFAF8', padding: 12 }}
          >
            {renderDataChangeRows(item, { excludeFields: ['status', 'onboardingDate'], showFieldName: false })}
          </div>
        );
      case 'updated_off_boarding_status_employee':
        return (
          <div
            className="mt-3 rounded-lg border-[1px] border-neutral-100 bg-neutral-50 p-4"
            style={{ marginTop: 3, borderRadius: 8, border: '1px solid #E4E3E2', background: '#FBFAF8', padding: 12 }}
          >
            {renderDataChangeRows(item, { excludeFields: ['status'], showFieldName: false })}
          </div>
        );
      case 'request_terminated_employee':
        return (
          <div
            className="mt-3 rounded-lg border-[1px] border-neutral-100 bg-neutral-50 p-4"
            style={{ marginTop: 3, borderRadius: 8, border: '1px solid #E4E3E2', background: '#FBFAF8', padding: 12 }}
          >
            {renderDataChangeRows(item, {
              mapField: {
                anticipatedEndDate: 'Date',
                reasonForTermination: 'reason',
              },
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const renderDataChangeRows = (
    log: IActivityLog,
    config?: { excludeFields?: string[]; showFieldName?: boolean; mapField?: Record<string, string> },
  ) => {
    const infoChanges = log?.newData;
    const { excludeFields, showFieldName = true, mapField } = config ?? {};

    return Object.keys(infoChanges)?.map((fieldKey, index) => {
      const oldValue = log.previousData[fieldKey as keyof IEmployee];
      const newValue = infoChanges[fieldKey as keyof IEmployee];

      if (oldValue === newValue || excludeFields?.includes(fieldKey)) {
        return null;
      }

      return (
        <div className="d-flex flex-nowrap flex-column flex-lg-row" key={index} style={{  marginTop: 8 }}>
          {showFieldName ? (
            <div
              className={`min-w-[136px] text-neutral-500 overflow-hidden`}
              style={{ marginRight: 28, minWidth: 160, color: '#AFADAC', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {mapField ? mapField[fieldKey] || _.startCase(fieldKey) : _.startCase(fieldKey)}
            </div>
          ) : null}
          <div className="d-flex flex-wrap">
            <div
              className="overflow-hidden"
              style={{ minWidth: 160, color: '#666564' }}
              title={getFieldValue(oldValue, fieldKey)} // Add tooltip for full text on hover
            >{`${getFieldValue(oldValue, fieldKey)||''}`}</div>
            <div
              className={`d-flex items-center text-neutral-900 overflow-hidden`}
              style={{ minWidth: 160, color: '#666564', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              <div className={`flex-shrink-0`} style={{ width: 40 }}>
                <IconArrowRight />
              </div>
              <div
                className="overflow-hidden"
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                title={getFieldValue(newValue, fieldKey)}
              >
                {getFieldValue(newValue, fieldKey)||''}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <Modal open={isModalOpen} title="Log Details" onCancel={() => handleCloseModal()} width={1000} centered footer={null}>
      <div className="container-fluid">
        <Spin tip="Loading" size="large" spinning={loading[activityLogActions.getActivityLog.typePrefix] || false}>
          {/* <div className="d-flex justify-content-between align-items-center py-4">
            <div className="d-flex justify-content-between align-items-center page-listing-title">
              <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
              <span>Log Details</span>
            </div>
          </div> */}

          <div className="bg-white rounded">
            <div className="p-4">
              {timestamps?.length?timestamps?.reverse()?.map((timestamp, kIndex) => {
                const list = groupedLogs[timestamp] || [];

                return (
                  <div key={kIndex}>
                    <div className="d-flex flex-column flex-xl-row" style={{ marginBottom: 4 }}>
                      <div className="d-flex">
                        <div className="d-flex flex-column" style={{ width: 20, paddingTop: 2 }}>
                          <div style={{ height: 16, width: 16, borderRadius: '50%', border: '3px solid #E4E3E2' }}></div>
                          <div
                            className="ml-[6px] mt-2 w-[3px] flex-grow-1 rounded-md bg-neutral-300"
                            style={{
                              marginLeft: 6,
                              marginTop: 8,
                              width: 3,
                              flex: 1,
                              borderRadius: 8,
                              backgroundColor: '#E4E3E2',
                            }}
                          />
                        </div>
                        <div className="w-[160px]" style={{ width: 180 }}>
                          <div
                            className="text-neutral-700"
                            style={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: '#8A8988',
                            }}
                          >
                            {dayjs.unix(list[0]?.createdAt).format(dateTimeFormat)}
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1" style={{ marginLeft: 40 }}>
                        {list?.map((item, index) => {
                          return (
                            <div key={index} className="flex-grow-1" style={{ marginBottom: 20 }}>
                              <div className="activity-log-item">
                                <div
                                  className="title"
                                  style={{
                                    color: '#8a8988',
                                    fontSize: 14,
                                    lineHeight: 1.42,
                                  }}
                                >
                                  {renderItemTitle(item)}
                                </div>
                                <div>{renderItemContent(item)}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }):<h4 className="text-center ">No record</h4>}
            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default ActivityLogDetailsModal;
